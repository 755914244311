import React from 'react'
import { Box, Button, Typography, Card, Stack, Divider, Chip, AvatarGroup, Grid } from '@mui/material';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import EditIcon from '@mui/icons-material/Edit';
import ChatIcon from '@mui/icons-material/Chat';
import MapIcon from '@mui/icons-material/Map';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/Login';
import { grey } from '@mui/material/colors';
import AppService from "../services/app.service";
import { ImageProfile } from './ImageProfile';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ShareIcon from '@mui/icons-material/Share';


const MeetCard = (meet, idx, nav, setReload, reload, setSnack, t, setDialog) => {
    const config = require('../config/default');
    const WEB_URL=config.web.endpoint
    function formatMessageTimestamp(timestamp) {
        const date = new Date(timestamp);
        const currentdate = new Date()
        const localdate=date.toLocaleDateString("fr-FR");
        const currentlocaldate=currentdate.toLocaleDateString("fr-FR");
        const localtime=date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });
        if (localdate===currentlocaldate) {
            return localtime;
        } else {
            return localdate + " " + localtime;
        }
    }

    
    let delelequit=<ExitToAppIcon/>
    let colordeletequit="warning"
    if (meet.iadmin===true) { 
        delelequit=<DeleteIcon/>
        colordeletequit="error"
    }
    
    const confirmDelete=()=>{
      if (meet.iadmin) {
        AppService.appDelete(`/meet/${meet.id}`).then(
            (response) => {
                setReload(!reload) // TODO, change this with not displaying this card instead of a full reload
                
            },
            (error) => {
              setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
            }
          );
      } else {
        // Quit meet
        AppService.appPost(`/meetleave/${meet.id}`).then(
          (response) => {
              setReload(!reload) // TODO, change this with not displaying this card instead of a full reload
              
          },
          (error) => {
            setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          }
        );
      }
    }
    
    const cancelDelete=()=>{
      //
    }
    
    const displayButtons = () => {
        let clipboard="Join this meet ! : " + WEB_URL + "/meetinfos/" + meet.id
        let joinclass="joinclass"+idx
        let shareclass="shareclass"+idx
        let editclass="editclass"+idx
        let chatclass="chatclass"+idx
        let quitclass="quitclass"+idx
        let mapclass="mapclass"+idx

        if (meet.imember===false) {
            return(
                <>
                <Button
                    sx={{mx:1}}
                    className={joinclass}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        AppService.joinMeet(meet.id, (results) => {
                          if (results.status===208) {
                            // TODO process errors
                            setSnack({message:t('meet.already_joined'), severity:"info", open:true})
                          } else if (results.status!==201) {
                              // TODO process errors
                              setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
                          } else {
                              // Navigate to mymeet
                              nav(`/mymeets/`);
                          }
                        });
                        
                    }}
                >
                  <LoginIcon />
                </Button>
                <CopyToClipboard text={clipboard}>
                  <Button sx={{mx:1}} className={shareclass} ariant="contained" color="primary" onClick={()=>{ setSnack({message:t('meet.clipboard'), severity:"info", open:true}) }}><ShareIcon /></Button>
                </CopyToClipboard>
                </>
            )
        } else {
            return(
                <Grid container rowGap={2} columnSpacing={{ xs:1 }} columns={{ xs:3, lg:5 }} justifyContent="center">
                    <Button
                        className={editclass}
                        sx={{mx:1}}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            nav(`/meet/${meet.id}`);
                        }}
                    >
                    <EditIcon/>
                    </Button>
                    <Button
                        className={chatclass}
                        sx={{mx:1}}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            nav(`/chat/?meetid=${meet.id}`);
                        }}
                    >
                        <ChatIcon/>
                    </Button>
                    <Button
                        sx={{mx:1}}
                        className={mapclass}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            nav(`/map/?meetid=${meet.id}`);
                        }}
                    >
                        <MapIcon/>
                    </Button>
                    <Button
                        sx={{mx:1}}
                        variant="contained"
                        className={quitclass}
                        color={colordeletequit}
                        onClick={() => {
                            // delete + reload
                            if (meet.iadmin===false) {
                            setDialog({open:true, title:t('common.areusure'), message:[t('meet.quit')], okButton:t('common.ok'), cancelButton:t('common.cancel'), callback:confirmDelete, cancelCallback:cancelDelete})
                            } else {
                            setDialog({open:true, title:t('common.areusure'), message:[t('meet.delete1'),t('meet.delete2')], okButton:t('common.ok'), cancelButton:t('common.cancel'), callback:confirmDelete, cancelCallback:cancelDelete})
                            }
                        }}
                    >
                        { delelequit }
                    </Button>
                    <CopyToClipboard text={clipboard}>
                    <Button sx={{mx:1}} className={shareclass} variant="contained" color="primary" onClick={()=>{ setSnack({message:t('meet.clipboard'), severity:"info", open:true}) }}><ShareIcon /></Button>
                    </CopyToClipboard>
                </Grid>
            )
        }

    }


    return (
    <div key={meet.id}>
        <Card sx={{minWidth:'300px', maxWidth:{ xs:'320px', sm:'500px'} }}>
            <Box sx={{ p: 2, display: 'flex' }}>
                <Stack spacing={0.5}>
                    <Typography fontWeight={700}>{meet.name}</Typography>
                    <ModeOfTravelIcon sx={{color: grey[500]}} /> 
                    <Typography variant="body" color="text.secondary">
                        {meet.location}
                    </Typography>
                </Stack>
            </Box>
            <Divider />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 1, py: 1, bgcolor: 'background.default' }}
            >
                <Typography variant="body" color="text.primary">
                  <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ px: 1, py: 1, bgcolor: 'background.default' }}
                  >
                    {t('common.date')} : {formatMessageTimestamp(meet.date)} 
                  </Stack>
                </Typography>
            </Stack>
            <Divider />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
            >
                <Typography variant="body" color="text.primary">
                {t('common.meeteurs')} : {meet.members_count}/{meet.max_members}
                </Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ px: 1, py: 1, bgcolor: 'background.default' }}
                >
                  <AvatarGroup max={4}>
                  {meet.meetmembers.map((meetmember,idx) => (
                      ImageProfile(meetmember.user.username, meetmember.user.first_name, meetmember.user.last_name, meetmember.user.profile.image_url)
                  ))}
                  </AvatarGroup>
                </Stack>
                
            </Stack>
            <Divider />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
            >
            <Typography variant="body" color="text.primary">
            {t('meet.tags_short')} : 
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {meet.tags?.map((value, id) => {
                    return(<Box sx={{ pr: 1, pb:1 }} key={value.id}>
                                <Chip
                                    label={value.name}
                                />
                        </Box>)
                }
                )}
            </Box>
            </Stack>
            <Divider />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
            >
                {displayButtons()}
            </Stack>
        </Card>

    </div>
    )
}

const NewMeetCard = (nav, t) => {
    return (
    <div key="NEWMEETCARD">
        <Card sx={{minHeight:'200px', minWidth:'300px'}}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                >
                    {t('meet.add_short')}
            </Stack>
            <Divider />
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                minHeight='180px'
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
            >
                <Button
                    style={{minWidth: '120px', minHeight: '60px'}}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        nav(`/meet`);
                    }}
                >
                    +
                </Button>
            </Stack>
        </Card>
    </div>
    )
}

export {
    MeetCard, NewMeetCard
}
  