import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import WorkIcon from '@mui/icons-material/Work';
import Grid from '@mui/material/Grid';
import { debounce } from '@mui/material/utils';
import AppService from "../services/app.service";


export default function Job({value, setValue, sx, label, readonly}) {
  if (label===undefined) { label="Job Title" }
  if (readonly===undefined) { readonly = false }
  //const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        AppService.searchJob(request.input, callback);
        
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <>
    <Box sx={sx}>
    <Autocomplete
      id="job-picker"
      className='jobs'
      filterOptions={(x) => x} // Override filter option (no filtering from autocomplete component)
      options={options}
      autoComplete
      readOnly={readonly}
      fullWidth
      filterSelectedOptions
      value={value}
      noOptionsText="No job selected"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}

      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => {
        const matches =
          option || [];

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <WorkIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>

                  <Box
                    key={matches}
                    component="span"
                    sx={{ fontWeight: matches.highlight ? 'bold' : 'regular' }}
                  >
                    {matches.label}
                  </Box>
              </Grid>
            </Grid>            
          </li>
        );
      }}
    />
    </Box>
    </>
  );
}