import React from 'react';
import { Chip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function DisplayRealm(realms, setRealms, realm) {

  const handleOpen=() => {
    setRealms(new Map(realms.set(realm[0], { dialog:true, isadmin:realm[1].isadmin, key:realm[1].key })))
  }

  const handleClose=() => {
    setRealms(new Map(realms.set(realm[0], { dialog:false, isadmin:realm[1].isadmin, key:realm[1].key })))
  }

  const handleAgree=() => {
    let prev=new Map(realms)
    prev.delete(realm[0])
    setRealms(new Map(prev))
  }

  if (realm[0]==="Public" || realm[1].isadmin===true) {
    return(
      <>
        <Chip key={realm[1].key} sx={{m:1}} label={realm[0]}/>
      </>
    )
  } else {
    return(
      <>
        <Dialog
          key={realm[0]}
          open={realm[1].dialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              If you quit the private group you cannot join it again till
              you receive another invitation.
              Are you sure that you want to quit "{realm[0]}" ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAgree} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Chip key={realm[1].key} sx={{m:1}} label={realm[0]} onDelete={()=>{
          handleOpen()
        }} />
      </>
    )
  }
}


export {
  DisplayRealm
}
  