import api from "./api";
import TokenService from "./token.service";
//const config = require('../config/default');

const appDelete = (url) =>  {
  return api.delete(url);
}

const appPost = (url) => {
  return api.post(url);
}

const getContacts = () => {
  return api.get("/contacts/");
};

const getMyMeets = () => {
  return api.get("/mymeets/");
};

const followGetLink = (link, callback) => {
  api.get(link).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

/*
const getMe = () => {
  return api.get("/me/");
};
*/

const apiUpdateField = (url, obj) => {
  return api.patch(url,obj)
}

const linkTag = (tagname) => {
  return api.post("/tags/", {
    name: tagname
  })
}

const updateLocation = (lon, lat, callback) => {
  api.post("/location/", {
    lon: lon,
    lat: lat
  }).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const unlinkTag = (tagid) => {
  return api.delete("/tags/" + tagid)
}

const searchTag = (tagname) => {
  return api.get("/tags/?s=" + tagname)
}

const newsearchTag = (tagname, callback) => {
  api.get("/tags/?s=" + tagname).then((response) => {
    callback(response.data.results)
  })
}

const deleteAccount = (callback) => {
  api.delete("/me/").then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const createMeet = (meet, callback) => {
  api.post("/meet/", meet).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const patchMeet = (meetId, meet, callback) => {
  api.patch("/meet/"+meetId, meet).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const joinMeet = (meetId, callback) => {
  api.post("/meetjoin/"+meetId).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const leaveMeet = (meetId, callback) => {
  api.post("/meetleave/"+meetId).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const updateFCMDevice = (token, callback) => {
  api.post("/devices/", token).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const getMeet = (meetId, callback) => {
  api.get("/meet/" + meetId).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const getMeetLocation = (meetId, callback) => {
  api.get("/meetlocation/" + meetId).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const getRealms = (callback) => {
  api.get("/realms/").then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const getRealm = (id, callback) => {
  api.get("/realms/"+id).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const createRealm = (data, callback) => {
  api.post("/realms/", data).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const deleteRealm = (id, callback) => {
  api.delete("/realms/" + id).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const patchRealm = (id, data, callback) => {
  api.patch("/realms/" + id, data).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const getMe = (callback) => {
  api.get("/me/").then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const patchMe = (data, callback) => {
  api.patch("/me/", data).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const inboxReadAll = (callback) => {
  api.post("/me/inboxread/").then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const patchProfile = (data, callback) => {
  api.patch("/profile/", data).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const patchProfileImage = (data, callback) => {
  api.patch("/profile/", data, {
    headers: {
        "Content-Type": "multipart/form-data",
    }
  }).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const getInbox = (callback) => {
  api.get("/me/inbox/").then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const getInfos = (callback) => {
  api.get("/me/infos/").then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const readInfos = (id, callback) => {
  api.get("/me/infos/"+id).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const validateInfos = (id, callback) => {
  api.post("/me/infos/"+id).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const deleteInfos = (id, callback) => {
  api.delete("/me/infos/"+id).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const infosReadAll = (callback) => {
  api.post("/me/infosread/").then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const deleteAllInfos = (callback) => {
  api.delete("/me/infos/").then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const getPOI = (min_lon, min_lat, max_lon, max_lat, days, id, callback) => {
  api.get("/meet/?min_lon="+min_lon+"&min_lat="+min_lat+"&max_lon="+max_lon+"&max_lat="+max_lat+"&days="+days+"&id="+id).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const getAddressFromCoordinates = (lon, lat, callback) => {
  api.get("/location/?lon="+lon+"&lat="+lat).then((response) => {
    callback(response)
  }).catch(function (error) {
    callback(error)
  })
}

const searchMeet = (location, radius, tags, callback) => {
  api.get("/meetsearch/?location="+location+"&r="+radius).then((response) => {
    callback(response)
  })

}

const searchLocation = (entry, callback) => {
  api.get("/location/?s=" + entry).then((response) => {
    callback(response.data)
  })

}

const searchJob = (entry, callback) => {
  api.get("/jobs/?s=" + entry).then((response) => {
    callback(response.data)
  })

}

const refreshAccessToken = (callback) => {
    const refreshToken = TokenService.getLocalRefreshToken();
    api.post("/token/refresh/", { refresh: refreshToken }).then((response) => {
      let access = response.data.access;
      TokenService.updateLocalAccessToken(access);
      callback(response.data.access)
    }).catch(function (error) {
      callback(false)
    })

}

const handleError = (error, param=undefined) => {
  const _content =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();
    //setContent(_content);
    console.log(_content);
    if (param!==undefined) {console.log("params:"+param)}
  if (error.response && error.response.status === 403) {
    console.log(error.response);
  }
}

const AppService = {
  appDelete,
  appPost,
  getContacts,
  getMe,
  getMeet,
  createMeet,
  searchMeet,
  patchMeet,
  getMyMeets,
  linkTag,
  unlinkTag,
  searchTag,
  newsearchTag,
  handleError,
  apiUpdateField,
  searchLocation,
  followGetLink,
  getPOI,
  getAddressFromCoordinates,
  refreshAccessToken,
  joinMeet,
  leaveMeet,
  getInbox,
  patchMe,
  patchProfileImage,
  patchProfile,
  updateFCMDevice,
  updateLocation,
  getRealms,
  getRealm,
  createRealm,
  deleteRealm,
  patchRealm,
  searchJob,
  getMeetLocation,
  getInfos,
  readInfos,
  validateInfos,
  deleteInfos,
  deleteAllInfos,
  infosReadAll,
  deleteAccount,
  inboxReadAll
};

export default AppService;
