import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';

const RequireAuth = ({ children }) => {
  const { auth } = useContext(authContext);
  const { loading } = auth;
  const prevRoute = useLocation();
  // Waiting for successfull auth infos
  if (loading) {
    return <p>Loading...</p>;
  }
  return auth.tokens ? children : <Navigate to="/login" state={{ prevRoute }}/>;
}

export default RequireAuth;
