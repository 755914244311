import React, { useState, useContext } from 'react';
import { Avatar, Box, Alert, TextField, Container, Button, Typography } from '@mui/material';
import AppService from '../services/app.service';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Tags from '../components/Tags';
import Location from '../components/Location';
import Job from '../components/Job';
import { useNavigate } from "react-router-dom";
import { userLogin, selectUser } from '../redux/reducers/userSlice.js'
import { useDispatch, useSelector } from 'react-redux';
import { Camera, CameraResultType } from '@capacitor/camera';
import { updateImageUrl } from '../redux/reducers/userSlice.js';
import { deepOrange } from '@mui/material/colors';
import { snackbarContext } from '../contexts/SnackbarContext';
import { useTranslation } from "react-i18next";

//const config = require('../config/default');

const User = () => {
  const { setSnack } = useContext(snackbarContext);
  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [location, setLocation] = useState("");
  const [job, setJob] = useState("");
  const [bio, setBio] = useState("");
  const [tags, setTags] = useState([]);
  const { t } = useTranslation();
  let nav=useNavigate();
  const dispatch = useDispatch();
  const reduxuser = useSelector(selectUser);
  var imageProfile=reduxuser.profile.image_url
  
  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        width:100,
        height:100,
        resultType: CameraResultType.Base64
      });

      let imageconv=await fetch ("data:image/png;base64,"+image.base64String)
      let imageblob=await imageconv.blob()
      const formData = new FormData();
      formData.append('image_url', imageblob, "imageprofile.png");
      
      AppService.patchProfileImage(formData, (results) => {
        if (results.status!==200) {
          // TODO process errors
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        } else {
          // update redux store
          setMessage();
          dispatch(updateImageUrl(results.data.image_url));
        }

      });
    } catch (e) {
      //console.log(e)
    }

  };

  const handleSubmit = (event) => {
    setMessage("");
    event.preventDefault();
    if (firstname==="" || lastname==="" || username==="") {
      setMessage(<Alert severity="warning">{t('user.required')}</Alert>)
    } else {
      let data={
        "first_name":firstname,
        "last_name":lastname,
        "username":username
      }
      let profile={}
      if (birthdate!==null) {
        profile['birth_date']= new Date(birthdate).toISOString().split('T')[0]
      }
      if (bio!=="") {
        profile['bio']=bio
      }
      if (job!=="" && job !==null) {
        profile['job_title']=job
      }
      if (tags.length!==0) {
        profile['update_tags']=tags
      }
      if (location!==undefined && location !=="") {
        if (location?.label!==undefined) {
          profile['location']=location.label
        } else {
          profile['location']=location
        }
      }
      if (Object.keys(profile).length!==0) {
        data['profile']=profile
      }
      AppService.patchMe(data, (results) => {
        if (results.status!==200) {
          // TODO process errors
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        } else {
          // update redux store
          setMessage();
          dispatch(userLogin(results.data));
          nav(-1);
        }

      });


    }
  }

    if (load===false) {
      AppService.getMe((results) => {
        if (results.status!==200) {
          // TODO process errors
          setLoad(true)
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          nav(-1);
        } else {
          setEmail(results.data.email)
          setFirstname(results.data.first_name)
          setLastname(results.data.last_name)
          setUsername(results.data.username)
          setBirthdate(results.data.profile.birth_date)
          setLocation(results.data.profile.location)
          setBio(results.data.profile.bio)
          setJob(results.data.profile.job_title)
          let tags=results.data.profile.tags
          let ntags=[]
          tags.map((tag, index) => (
            ntags.push(tag.name)
          ))
          setTags(ntags)
          setLoad(true)
        }
      })
    }


    if (load===false) {
      return (<>Loading...</>)
    } else {
      return(
        <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'calc(100vh + 150px)'
          }}
        >
          <Typography component="h1" variant="h5" marginBottom="10px">
            {t('user.myprofile')}
          </Typography>
          {message}
          <Avatar className="profilimage" alt={username} src={imageProfile} sx={{bgcolor: deepOrange[500], width: 100, height: 100, mb:2}} 
            onClick={(event) => {
                takePicture()
              }
            }
          />
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ alignItems:'center', display: 'grid', gap:1, gridTemplateColumns: 'repeat(20, 1fr)' }}>
            <TextField
              fullWidth
              sx={{ gridColumn: "span 20" }} 
              required
              value={firstname}
              onChange={(newValue) => {
                setFirstname(newValue.target.value);
              }}
              inputProps={{ maxLength: 50 }}
              id="firstname"
              label={t('user.firstname')}
              name="firstname"
              variant="outlined"
            />
            <TextField
              sx={{ gridColumn: "span 20" }} 
              required
              inputProps={{ maxLength: 50 }}
              value={lastname}
              onChange={(newValue) => {
                setLastname(newValue.target.value);
              }}
              fullWidth
              id="lastname"
              label={t('user.lastname')}
              name="lastname"
              variant="outlined"
            />
            <TextField
              sx={{ gridColumn: "span 20" }} 
              required
              inputProps={{ maxLength: 150 }}
              value={username}
              onChange={(newValue) => {
                setUsername(newValue.target.value);
              }}
              fullWidth
              id="username"
              label={t('user.username')}
              name="username"
              variant="outlined"
            />
            <Job label={t('user.jobtitle')} value={job} setValue={setJob} sx={{ mt:"1", gridColumn: "span 20" }} />
            <TextField
              sx={{ gridColumn: "span 20" }} 
              disabled
              value={email}
              onChange={(newValue) => {
                setEmail(newValue.target.value);
              }}
              fullWidth
              inputProps={{ maxLength: 254 }}
              id="email"
              label={t('common.email')}
              name="email"
              variant="outlined"
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label={t('user.birthday')}
                inputFormat="DD/MM/yyyy"
                renderInput={(params) => <TextField id="datetime" variant="outlined" fullWidth {...params} sx={{ gridColumn: "span 20" }}  />}
                value={birthdate}
                onChange={(newValue) => {
                  setBirthdate(newValue);
                }}
            />
            </LocalizationProvider>
            <Location label={t('user.location')} value={location} setValue={setLocation} sx={{ mt:"1", gridColumn: "span 19" }} />
            <Tags label={t('user.tags')} tags={tags} setTags={setTags} sx={{ gridColumn: "span 20" }}/>
            <Box display="flex" justifyContent="center"  sx={{ gridColumn: "span 20"}}>
              <Button
                variant="contained"
                onClick={()=> {nav(-1)}}
                sx={{mr:1}}
              >
                {t('common.cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
              >
                {t('common.save')}
              </Button>
            </Box>
            <Box display="flex" justifyContent="center"  sx={{ gridColumn: "span 20"}}>
              <Button
                variant="contained"
                onClick={()=> {nav("/byebye")}}
                sx={{ml:1}}
                color="error"
              >
                {t('common.deleteaccount')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      );
    }

};

export default User;
