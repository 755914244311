import React from 'react'
import { Box, Typography } from '@mui/material'
//import { BleClient } from '@capacitor-community/bluetooth-le';
//import { BackgroundRunner } from "@capacitor/background-runner";
//import { Geolocation } from '@capacitor/geolocation';

function Ble() {
  
  /*const test = async() =>  {
    const location = await Geolocation.getCurrentPosition()
    console.log(location)
  }*/

  const demo = () => {
    
    let top=window.getComputedStyle(document.documentElement).getPropertyValue('--safe-area-top');
    let left=window.getComputedStyle(document.documentElement).getPropertyValue('--safe-area-left');
    let bottom=window.getComputedStyle(document.documentElement).getPropertyValue('--safe-area-bottom');
    let right=window.getComputedStyle(document.documentElement).getPropertyValue('--safe-area-right');
    let a="top " + top + " left " + left + " bottom " + bottom + " right " + right
    console.log(a)
    alert(a)

  }

  /*const onTestCapLocation = async () => {
    try {
      const response = await BackgroundRunner.dispatchEvent({
        label: "com.Meeteurs.background.task",
        event: "syncTest"
      });
      console.log(`success: ${JSON.stringify(response)}`);
    } catch (err) {
      console.log(`ERROR: ${err}`);
    }
  };*/
  
  //onTestCapLocation();


  return (
    <Box
      sx={{
        margin:2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        minHeight: 'calc(100vh + 150px)'
      }}
    >
      <Typography variant="h6" onClick={()=>{demo()}}>
        Locations update test
      </Typography>
 
    </Box>
  );
}

export default Ble
