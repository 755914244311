import React from 'react'
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import { useTranslation } from "react-i18next";
const config = require('../config/default');

function Home() {

  const beer=() => {
    return(
      <>
        Made of <SportsBarIcon /> & pretzels !
      </>
    );
  }

  const { t } = useTranslation();
  const MEDIA_URL=config.media.endpoint
  const android_package=MEDIA_URL+"/media/static/meeteurs_alpha.apk" 
  const ios_package="itms-services://?action=download-manifest&url=https://dev.meeteurs.com:1443/media/static/manifest.plist"

  return (
    <Box
      sx={{
        margin:2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        minHeight: 'calc(100vh + 150px)'
      }}
    >
      <Typography component="h2" variant="h4" marginBottom="40px" marginTop="40px">
          <Box sx={{               
              mr: 2,
              fontFamily: 'monospace',
              fontWeight: 300,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none' }}>
            MEETEURS
            <sup style={{fontSize:"8.0pt"}}>Alpha</sup>
            
          </Box>
      </Typography>
      <Typography variant="body">
        
        Bienvenue sur l'App Mobile<br/>
          
        {t('home.restrictions.title')}<br/>

        
          <List dense={true}>
            <ListItem sx={{display:'grid', listStyleType: 'disc'}}>
              <ListItemText
                sx={{ display: 'list-item' }}
                primary="Pas d'envoie de mail"
              />
              <ListItemText
                sx={{ display: 'list-item' }}
                primary="Utilisez obligatoirement la connexion via Google, la validation par mail n'étant pas possible."
              />
              <ListItemText
                sx={{ display: 'list-item' }}
                primary="La base de données peut être vidée sans préavis."
              />
              <ListItemText
                sx={{ display: 'list-item' }}
                primary={beer()}
              />
            </ListItem>
          </List>
          <br/><br/><br/><u>{t('home.logininfo')}</u><br/>
      </Typography>
 
    </Box>
  );
}

export default Home
