import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AppService from "../../services/app.service";

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    states: {},
    value: {},
  },
  reducers: {
    userLogin: (state, action) => {
      state.value = action.payload
    },
    userLogout: (state) => {
      state.value = "anonymous"
    },
    updateUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload
    },
    updateImageUrl: (state, action) => {
      let updatestate = {...state.value,
        profile: {
          ...state.value.profile,
          image_url: action.payload
        }
      }
      state.value=updatestate
    },
    updateLocalization: (state, action) => {
      let updatestate = {...state.value,
        profile: {
          ...state.value.profile,
          lat: action.payload[0],
          lon: action.payload[1]
        }
      }
      state.value=updatestate
    },   
    // setStates called from asyncthunk for updating loading state and error messages
    setStates: (state, action, data) => {
      // Update state.requestStates
      let updatestate = {...state.states}
      if (updatestate[action.meta.arg.reduxkey]===undefined) {
        updatestate[action.meta.arg.reduxkey]={}
      }
      updatestate[action.meta.arg.reduxkey].status=data['status']
      if (data['status']==="fulfilled") { // if the API call was successfull, disable editing
        updatestate[action.meta.arg.reduxkey].edit=false
      }
      updatestate[action.meta.arg.reduxkey].message=data['message']
      state.states=updatestate
    },
    // Update user.states.field.edit
    updateUserFieldState: (state, action) => {
      // if entry already exists just assign the edit value
      if (state.states[action.payload[0]]) {
        state.states[action.payload[0]].edit = action.payload[1]
        if (action.payload[1]===false) {
          state.states[action.payload[0]].status = "fulfilled"
          state.states[action.payload[0]].message = []
        }

      } else {
        // else need to create the object and assign it
        let update={}
        update.edit=action.payload[1]
        state.states[action.payload[0]]=update
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMe.pending, (state, action) => {
        userSlice.caseReducers.setStates(state, action, normalizeErrors(action))
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        state.value = action.payload
        userSlice.caseReducers.setStates(state, action, normalizeErrors(action))
      })
      .addCase(fetchMe.rejected, (state, action) => {
        userSlice.caseReducers.setStates(state, action, normalizeErrors(action))
      })
      .addCase(addTag.pending, (state, action) => {

      })
      .addCase(addTag.fulfilled, (state, action) => {
        let updatestate = {...state.value,
                          profile: {
                            ...state.value.profile,
                            tags: [
                              ...state.value.profile.tags,
                              {id: action.payload.id, name: action.payload.name}
                            ]
                          }
        }
        state.value=updatestate
      })
      .addCase(addTag.rejected, (state, action) => {

      })
      .addCase(deleteTag.pending, (state, action) => {

      })
      .addCase(deleteTag.fulfilled, (state, action) => {

        let updatestate = {...state.value,
                          profile: {
                            ...state.value.profile,
                            tags: removeItem([...state.value.profile.tags], action.meta.arg)
                          }
        }
        state.value=updatestate
      })
      .addCase(deleteTag.rejected, (state, action) => {

      })
      .addCase(editField.pending, (state, action) => {
        userSlice.caseReducers.setStates(state, action, normalizeErrors(action))
      })
      .addCase(editField.fulfilled, (state, action) => {
        let arg=action.meta.arg
        let updatestate={...state.value}
        let profile={...state.value.profile}
        switch (arg.reduxkey) {
          case 'first_name':
            updatestate['first_name']=arg.value
            break
          case 'last_name':
            updatestate['last_name']=arg.value
            break;
          case 'bio':
            profile['bio']=arg.value
            updatestate['profile']=profile
            break;
          case 'location':
            profile['location']=arg.value
            updatestate['profile']=profile
            break;
          case 'birth_date':
            profile['birth_date']=arg.value
            updatestate['profile']=profile
            break;
          default:
            state.value=updatestate
          }
        state.value=updatestate
        userSlice.caseReducers.setStates(state, action, normalizeErrors(action))
      })
      .addCase(editField.rejected, (state, action) => {
        userSlice.caseReducers.setStates(state, action, normalizeErrors(action))
      })
  }
});

const removeItem = (tagarray, toremove) => {
  let returnArray=tagarray.filter(arrvalue => arrvalue.id !== toremove.id);
  return returnArray;
}

// Normalize error format before storing
const normalizeErrors = (action) => {
  let key=""
  if (action.meta && action.meta.arg && action.meta.arg.reduxkey) {
    key=action.meta.arg.reduxkey
  }
  let msg={}
  msg['status']=action.meta.requestStatus
  msg['message']=[]
  if (action.meta.requestStatus==="rejected") {
    if (action.error.message) {
      msg['message'].push(action.error.message) // error message didn't have apifield related nor rank error value (may happen with API error message so we keep same format)
    } else {
      msg['message'].push(action.error)
    }
    if (action.payload!==undefined) {
      if (key!=="") {
        msg['message']=action.payload[key]
      } else {
        msg['message']=action.payload
      }
    }
  }
  return msg
}

export const fetchMe = createAsyncThunk(
  'user/fetchMe',
  async () => {
    const response = await AppService.getMe()
    return response.data
  }
)

export const addTag = createAsyncThunk(
  'user/addTag',
  async tagName => {
    const response = await AppService.linkTag(tagName)
    return response.data
  }
)

export const deleteTag = createAsyncThunk(
  'user/deleteTag',
  async tagName => {
    const response = await AppService.unlinkTag(tagName.id)
    return response.data
  }
)

export const editField = createAsyncThunk(
  'user/editField',
  async ({url, apifield, data}, { rejectWithValue }) => {
    // Need this try / catch for getting payload API error in extra reducer
    try {
      //TODO : check if new value is different of the stored value
      const response = await AppService.apiUpdateField(url, data)
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

// Action creators are generated for each case reducer function
export const { updateLocalization, updateUser, updateUserField, userLogout, userLogin, setStates, updateUserFieldState, updateImageUrl } = userSlice.actions;

export const selectUser = (state) => state.user.value;
export const selectState = (reduxkey) => (state) => {
  if (state.user && state.user.states) {
    return state.user.states[reduxkey]
  }
  return undefined
}
export const selectStore = (state) => state.user;


export default userSlice.reducer;
