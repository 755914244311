import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Button, Badge, IconButton, Tooltip, Popper, Grow, Paper, ClickAwayListener, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, ListItemIcon, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import useWebSocket from "react-use-websocket";
import TokenService from "../services/token.service";
import AppService from "../services/app.service";
import { formatMessageTimestamp } from "./helpers";
import { useNavigate } from 'react-router-dom';
const config = require('../config/default');

const Notifications = (props) => {
  const t = props.t
  const setSnack = props.setSnack
  const user=TokenService.getUser()?.username;
  const [accesstoken, setAccesstoken]=React.useState(TokenService.getLocalAccessToken());
  const [message_count, setMessage_count] = React.useState(0);
  const [anchorMessageEl, setAnchorMessageEl] = React.useState(null);
  const [messages, setMessages] = React.useState()

  const [notif_count, setNotif_count] = React.useState(0);
  const [anchorNotifEl, setAnchorNotifEl] = React.useState(null);
  const [notifs, setNotifs] = React.useState()

  const scrollMessagesRef = React.useRef(null);
  const scrollNotifsRef = React.useRef(null);
  const nav=useNavigate();

 useWebSocket(user ? config.api.wss + "/notif/": null, {
    queryParams: {
      token: user ? accesstoken : "",
    },
    onOpen: () => {
      //console.log("Connected!");
    },
    onClose: () => {
      //console.log("Disconnected!");
    },
    // New onMessage handler
    onMessage: (e) => {
      const data = JSON.parse(e.data);
      switch (data.type) {
        case "notif_update":
          setNotif_count(data.message);
          break;
        case "message_update":
          setMessage_count(data.message);
          break;
        case "TokenError":
          AppService.refreshAccessToken((results) => { 
            if (results!==false) {
              setAccesstoken(results);
            } else {
              // If x times not able to refresh, logout
              console.log("Unable to refresh token...")
              nav("/logout")
            }
          })
          break;
        default:
          console.error("Unknown message type!",data);
          break;
      }
    },
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000
  });

  React.useEffect(() => {
    scrollMessagesRef.current?.scrollIntoView({ behaviour: "smooth" });
  }, [messages]);

  React.useEffect(() => {
    scrollNotifsRef.current?.scrollIntoView({ behaviour: "smooth" });
  }, [notifs]);

  const closeMessageRedirect = (meetid) => {
    setAnchorMessageEl(null);
    nav("/chat/?meetid=" + meetid)
  }

  const readAllMessages = () => {
    AppService.inboxReadAll((results) => {
      if (results.status===200) {
        setAnchorMessageEl(null);
      } else {
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      }
    })
    
  }

  const deleteAllNotifications = () => {
    AppService.deleteAllInfos((results) => {
      if (results.status===204) {
        setAnchorNotifEl(null);
      } else {
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      }
    })
    
  }

  const readAllNotifications = () => {
    AppService.infosReadAll((results) => {
      if (results.status===200) {
        setAnchorNotifEl(null);
      } else {
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      }
    })
    
  }

  const updateInfos = () => {
    AppService.getInfos((results) => { 
      setNotifs()
      if (results.status===200) {
        setNotifs(generateNotifList(results.data))
      } else {
        // generate error
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      }
    })
  }

  const notifRead = (id) => {
    AppService.readInfos(id, (results) => { 
      if (results.status===200) {
        updateInfos();
      } else {
        switch (results?.response?.status) {
          case 404:
            break;
          case 403:
            setSnack({message:t('common.forbidden'), severity:"error", open:true})
            break;
          default:
            setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        }
      }
    })
  }

  const validateRead = (id) => {
    AppService.validateInfos(id, (results) => { 
      if (results.status===200) {
        updateInfos();
      } else if (results.status===208) {
        setSnack({message:t('meet.already_joined'), severity:"warning", open:true})
      } else {
        switch (results?.response?.status) {
          case 404:
            setSnack({message:t('common.notfound'), severity:"error", open:true})
            break;
          case 403:
            setSnack({message:t('common.forbidden'), severity:"error", open:true})
            break;
          case 423:
            setSnack({message:t('meet.full'), severity:"error", open:true})
            break;
          default:
            setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        }
      }
    })

  }

  const deleteRead = (id) => {
    AppService.deleteInfos(id, (results) => { 
      if (results.status===204) {
        updateInfos();
      } else {
        switch (results?.response?.status) {
          case 404:
            break;
          default:
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        }
      }
    })

  }

  const generateNotifList = (array) => { 
    if (array.length===0) {
      return(
      <ClickAwayListener onClickAway={handleNotifClose}>
        <List>
          <ListItem sx={{
              '&:hover': {
                  cursor: 'pointer'
              }
            }} 
            alignItems="flex-start"
            onClick={handleNotifClose}
          >
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body"
              color="text.primary"
            >
              {t('common.no_notifications')}
            </Typography>           
          </ListItem>
        </List>
      </ClickAwayListener>
      )
    } else {

      const displayAction=(notif) => {
        if (notif.answer==="A") {
          return(
            <ListItemIcon>
              <CheckCircleIcon onClick={()=>{validateRead(notif.id)}}/>
              <CancelIcon onClick={()=>{deleteRead(notif.id)}}/>
            </ListItemIcon>
          )
        } else {
          return(
            <ListItemIcon>
              <CancelIcon onClick={()=>{deleteRead(notif.id)}}/>
            </ListItemIcon>
          )
        }
      }

      return (
        <ClickAwayListener onClickAway={handleNotifClose}>
          <List sx={{ 
            maxHeight:"calc(100vh - 84px)",
            overflowY:"scroll"
          }}>
          {array.map((notif, index) => (
            <>
            <ListItem sx={{
                '&:hover': {
                    cursor: 'pointer'
                },
                backgroundColor: notif.read ? 'inherit': 'primary.main'
              }} 
              alignItems="flex-start"
              onClick={()=>{notifRead(notif.id)}}
            >
              {displayAction(notif)}
              <ListItemText
                primary={notif.description}
                secondary={formatMessageTimestamp(notif.date)}
              />

            </ListItem>
            </>
          ))}
            <ListItem ref={scrollNotifsRef}></ListItem>
            <Grid container justifyContent="center">
                <Button
                    sx={{m:1}}
                    variant="contained"
                    onClick={()=> {deleteAllNotifications()}}
                >
                  {t('common.deleteall_notifications')}
                </Button>
                <Button
                    sx={{m:1}}
                    variant="contained"
                    onClick={()=> {readAllNotifications()}}
                >
                  {t('common.readall_notifications')}
                </Button>
              </Grid>
          </List>
        </ClickAwayListener>
      )
    }

  }

  const generateMessagesList = (array) => { 
    if (array.length===0) {
      return(
      <ClickAwayListener onClickAway={handleMessageClose}>
        <List>
          <ListItem sx={{
              '&:hover': {
                  cursor: 'pointer'
              }
            }} 
            alignItems="flex-start"
            onClick={handleMessageClose}
          >
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body"
              color="text.primary"
            >
              {t('common.no_messages')}
            </Typography>           
          </ListItem>
        </List>
      </ClickAwayListener>
      )
    } else {
      return (
        <ClickAwayListener onClickAway={handleMessageClose}>
          <List sx={{ 
            maxHeight:"calc(100vh - 84px)",
            overflowY:"scroll"
          }}>
          {array.map((msg, index) => (

            <ListItem sx={{
                '&:hover': {
                    cursor: 'pointer'
                },
                backgroundColor: msg.read ? 'inherit': 'primary.main'
              }} 
              alignItems="flex-start"
              onClick={() => {closeMessageRedirect(msg.meetmessage.meet.id)}}
            >
              <ListItemAvatar>
                <Avatar alt={msg.from_user.username} src={imageprofile(msg.from_user.profile.image_url)}/>
              </ListItemAvatar>
              <ListItemText
                primary={msg.from_user.username + " in " + msg.meetmessage.meet.name}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body"
                      color="text.primary"
                    >
                      {formatMessageTimestamp(msg.meetmessage.date) + " "}
                    </Typography>
                    {msg.meetmessage.content}
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
            <ListItem ref={scrollMessagesRef}></ListItem>
              <Box sx={{ textAlign:"center" }}>
                <Button
                    variant="contained"
                    onClick={()=> {readAllMessages()}}
                >
                  {t('common.readall_messages')}
                </Button>
              </Box>
          </List>
        </ClickAwayListener>
      )
    }

  }


  const openMessages = (event) => {
    // Retrieve messages
    AppService.getInbox((results) => { 
      setMessages()
      if (results.status===200) {
        //console.log(results.data)
        setMessages(generateMessagesList(results.data))
      } else {
        // generate error
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      }
    })
    setAnchorMessageEl(anchorMessageEl ? null : event.currentTarget);
  }

  
  const openNotifs = (event) => {
    // Retrieve messages
    updateInfos();
    setAnchorNotifEl(anchorNotifEl ? null : event.currentTarget);
  }

  

  const handleMessageClose = (event) => {
    setAnchorMessageEl(null);
  };

  const handleNotifClose = (event) => {
    setAnchorNotifEl(null);
  };

  const openMessageMenu = Boolean(anchorMessageEl);
  const openNotifMenu = Boolean(anchorNotifEl);

  function imageprofile(image_url) {
    var image="/"
    if (image_url!==null) {
      image=config.media.endpoint+image_url
    }
    return image
  }

  return (
      <React.Fragment>
            <Tooltip title="Notifications">
              <>
                <IconButton onClick={openNotifs} sx={{ mr: 0 }} className='menualertes'>
                  <Badge badgeContent={notif_count} max={9} color="secondary">
                    <NotificationsIcon color="action" />
                  </Badge>
                </IconButton>
                <Popper 
                  open={openNotifMenu}
                  anchorEl={anchorNotifEl}
                  role={undefined}
                  placement="top-end"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom-start' ? 'left top' : 'left bottom',
                      }}
                    >
                    <Paper>{notifs}</Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            </Tooltip>
            <Tooltip title="Messages">
              <>
                <IconButton onClick={openMessages} sx={{ mr: 2 }} className='menumessages'>
                  <Badge badgeContent={message_count} max={9} color="secondary">
                    <MailOutlineIcon color="action" />
                  </Badge>
                </IconButton>
                <Popper
                  open={openMessageMenu}
                  anchorEl={anchorMessageEl}
                  role={undefined}
                  placement="top-end"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom-start' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        {messages}
                      </Paper>
                    </Grow>
                  )}
              
            
                </Popper>
              </>
            </Tooltip>

      </React.Fragment>
  )
}

export {
  Notifications
}
