import React, { useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/reducers/userSlice.js'
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem, Divider } from '@mui/material';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import { deepOrange, grey } from '@mui/material/colors';
import { MapMenu } from '../components/MapMenu';
import { Notifications } from './Notifications';
import { DarkLight } from './DarkLight';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import { yellow } from '@mui/material/colors';
import { useTranslation } from "react-i18next";
import { snackbarContext } from '../contexts/SnackbarContext';
import { appsettingsContext } from '../contexts/AppsettingsContext.js';
import setTour from './Tour.jsx';

function TopNavBar () {
  let location = useLocation();
  const { setSnack } = useContext(snackbarContext);
  const { appsettings, setAppsettings } = React.useContext(appsettingsContext);
  const { t } = useTranslation();
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const reduxuser = useSelector(selectUser);

  var username="anonymous"; // default username name
  var imageprofil="/"; // default image
  // Display anonymous unless we retrieved a username
  let avatarcolor=grey[400]
  if (reduxuser.username!==undefined) {
    username=reduxuser.username;
    imageprofil=reduxuser.profile.image_url
    avatarcolor=deepOrange[500]
  }

  let navigate = useNavigate();
  const logoutLink = () => {
    navigate("/logout");
   
  }


  const launchTour = (event) => {
    event.preventDefault();
    const setSteps=appsettings["setSteps"]
    const breakpoint=appsettings["breakpoint"]
    const setRun=appsettings["setRun"]
    
    setTour(location, setSteps, t, breakpoint)

    setRun(true)
    
  }

  const generateAvatar = () => {
    if (reduxuser?.type==="P") {
      return(
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <SmallAvatar>
              <StarIcon sx={{ color: yellow[500] }}/>
            </SmallAvatar>
          }
        >
          <Avatar alt={username} src={imageprofil} sx={{bgcolor: avatarcolor}}/>
        </Badge>
        )
    } else {
      return(<Avatar alt={username} src={imageprofil} sx={{bgcolor: avatarcolor}} />)
    }
  }

  const dashboardMenu = (handleCloseUserMenu, navigate) => {
    if (reduxuser?.type==="P") {
      return(
        <Divider>
          <MenuItem key="dashboard" onClick={() => {
            handleCloseUserMenu()
            navigate("/dashboard")
          }}>
            <Typography textAlign="center">{t('toolbar.dashboard')}</Typography>
          </MenuItem>
        </Divider>
        )
    }
  }

  //const { setAuthData } = React.useContext(authContext)

  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters variant="dense">
          <ModeOfTravelIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            className='menumeeteurs'
            variant="h6"
            noWrap
            component="a"
            onClick={()=>{navigate("/")}}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 300,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            MEETEURS
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              className='menumeetscompact'
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key={"about"} onClick={()=>{
                handleCloseNavMenu()
                navigate("/about")
              }}>
                  <Typography textAlign="center">{t('toolbar.about')}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem key={"map"} onClick={()=>{
                handleCloseNavMenu()
                navigate("/map")
              }}>
                  <Typography textAlign="center">{t('toolbar.map')}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={(event) => {
                      handleCloseNavMenu()
                      navigate("/meetexplore")
              }}>
                 <Typography textAlign="center">{t('toolbar.meetexplore')}</Typography>
              </MenuItem>
              <MenuItem onClick={(event) => {
                      handleCloseNavMenu()
                      navigate("/mymeets")
              }}>
                 <Typography textAlign="center">{t('toolbar.mymeets')}</Typography>
              </MenuItem>
              <MenuItem onClick={(event) => {
                      handleCloseNavMenu()
                      navigate("/meet")
              }}>
                 <Typography textAlign="center">{t('toolbar.createmeet')}</Typography>
              </MenuItem>
            </Menu>
          </Box>
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={()=>{navigate("/")}}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <ModeOfTravelIcon className='menumeeteurs_c' sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
                className='menumap'
                key="Map"
                onClick={()=>{
                  handleCloseNavMenu()
                  navigate("/map")
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {t('toolbar.map')}
            </Button>
            <MapMenu />
            <Button
                key="About"
                onClick={()=>{
                  handleCloseNavMenu()
                  navigate("/about")
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {t('toolbar.about')}
            </Button>
            <Button
                key="LaunchTour"
                onClick={launchTour}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {t('toolbar.tour')} 
            </Button>
          </Box>
          <HelpIcon 
            sx={{ mr: '12px', display: { xs: 'flex', md: 'none' } }}
            onClick={launchTour}
          />
          <DarkLight />
          <Box sx={{ flexGrow: 0 }}>
            <Notifications t={t} setSnack={setSnack}/>
            <Tooltip title={t('toolbar.opensettings')}>
              <IconButton onClick={(event) => { 
                if (username==='anonymous'){
                  navigate("/login")
                } else {
                  handleOpenUserMenu(event)
                }
               }}
               className="menulogin"
               sx={{ p: 0 }}>
               {generateAvatar()}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mb: '45px' }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="profile" onClick={() => {
                  handleCloseUserMenu()
                  navigate("/user")
              }}>
                <Typography textAlign="center">{t('toolbar.profile')}</Typography>
              </MenuItem>
              <MenuItem key="settings" onClick={() => {
                  handleCloseUserMenu()
                  navigate("/settings")
              }}>
                <Typography textAlign="center">{t('toolbar.settings')}</Typography>
              </MenuItem>
              {dashboardMenu(handleCloseUserMenu, navigate)}
              <MenuItem key="logoutlink" onClick={() => {
                  handleCloseUserMenu()
                  logoutLink()
              }}>
                <Typography textAlign="center">{t('toolbar.logout')}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );

}

export default TopNavBar
