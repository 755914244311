import React from 'react'
import { useTranslation } from "react-i18next";
import { Box, Typography, Container } from '@mui/material';
const config = require('../config/default');

function Oups() {
  const MEDIA_URL = config.media.endpoint;
  const { t } = useTranslation();

  const image = MEDIA_URL+'/media/static/404.jpg';
  return (
    <Box
    sx={{
      margin:2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      minHeight: 'calc(100vh + 150px)'
    }}
    >
      <Container maxWidth="sm" sx={{ marginTop:5 }} >
        <Typography variant="h6" sx={{ mb:2 }}>
          {t('oups.title')}
        </Typography>
        {t('oups.text')}<br/>
        <img src={image} alt="404" />
      </Container>
    </Box> 
  );
}

export default Oups
