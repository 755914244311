import React from 'react'
import { Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material'
import SportsBarIcon from '@mui/icons-material/SportsBar';
import { useTranslation } from "react-i18next";


const config = require('../config/default');

function Home() {

  const beer=() => {
    return(
      <div id="beer">
        Made of <SportsBarIcon /> & pretzels !
      </div>
    );
  }

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        margin:2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        minHeight: 'calc(80vh)'
      }}
    >
      
      <Typography component="h2" variant="h4" marginBottom="40px">
          <Box sx={{               
              mr: 2,
              fontFamily: 'monospace',
              fontWeight: 300,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none' }}>
            MEETEURS
            <sup style={{fontSize:"8.0pt"}}>Alpha</sup>
            
          </Box>
      </Typography>
      <Typography variant="body">
        Bienvenue sur l'application Meeteurs<br/>
        <br/>Les App mobiles sont disponibles sur le store Android et sur Testflight sur Apple.<br/>
        <br/><a href="mailto:meeteurs.com@gmail.com?subject=Demande d'accès à Meeteurs Alpha"><Typography color="red">Il est nécessaire pour accéder à l'alpha release d'en faire la demande à meeteurs.com@gmail.com en précisant:</Typography></a>
        <br/>- Votre identifiant (email) Gmail (pour l'applicatin Android ET Apple)
        <br/>- Votre compte Apple (uniquement sur IOS)
        
        <br/><br/>
        {t('home.restrictions.title')}<br/>
          <List dense={true}>
            <ListItem sx={{display:'grid', listStyleType: 'disc'}}>
              <ListItemText
                sx={{ display: 'list-item' }}
                primary="Pas d'envoie de mail"
              />
              <ListItemText
                sx={{ display: 'list-item' }}
                primary="Utilisez obligatoirement la connexion via Google, la validation par mail n'étant pas possible."
              />
              <ListItemText
                sx={{ display: 'list-item' }}
                primary="La base de données peut être vidée sans préavis."
              />
              <ListItemText
                sx={{ display: 'list-item' }}
                primary={beer()}
              />
            </ListItem>
          </List>
        

        <br/><br/><br/><u>{t('home.logininfo')}</u><br/>
      </Typography>
      

    </Box>
  );
}

export default Home
