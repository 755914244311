import React from 'react'
import { Routes, Route, useNavigate, Navigate } from "react-router-dom"; 
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import RequireAuth from '../components/RequireAuth';
import { authContext } from "../contexts/AuthContext";
import Home from './Home'
import About from './About'
import User from './User'
import Private from './Private'
import Login from './Login';
import Register from './Register';
import Thanks from './Thanks';
import Mobile from './Mobile';
import Map from './Map';
import Chat from './Chat';
import Mymeets from './Mymeets';
import Meet from './Meet';
import Meetexplore from './Meetexplore';
import Settings from './Settings';
import Google from './Google';
import Logout from './Logout';
import Realm from './Realm';
import Ble from './Ble';
import Dashboard from './Dashboard';
import UpdatePage from './UpdatePage';
import Oups from './Oups';
import Meetinfos from './Meetinfos';
import Privacy from './Privacy';
import Byebye from './Byebye';

function Routing () {

  let navigate=useNavigate();
  // Get min build version
  const [build, setBuild] = React.useState(null);
  const [appBuild, setAppBuild] = React.useState(null);
  const { auth } = React.useContext(authContext)

  const config = require('../config/default');
  React.useEffect(() => {
    fetch(config.api.endpoint + "/build")
      .then(response => response.json())
      .then(json => setBuild(json))
      .catch(error => console.error(error));

    if (Capacitor.getPlatform()!=="web") {
      App.getInfo().then(info => {
        setAppBuild(info.build);
      })
    }

  });
  //console.log("build",build)
  //console.log("appBuild", appBuild)

  if (build!==null && appBuild!==null) {
    if (parseInt(build)>parseInt(appBuild)) { // Detect app version and redirect to force update if needed
      return(
        <Routes>
          <Route path="/update" element={<UpdatePage />} />
          <Route path="*" element={<Navigate to="/update" replace />} />
        </Routes>
      )
    }
  }


  let authdata=JSON.parse(auth?.tokens)

  function getHome() {
    if (authdata?.access !== undefined) {
      return(<Route path="/" element={<Map />} />)
    }

    if (Capacitor.getPlatform()==="web") {
      return (<Route path="/" element={<Home />} />)
    } else {
      return (<Route path="/" element={<Mobile />} />)
    }
  }

  function getLogging() {
    if (authdata?.access !== undefined) {
      return(<><Route path="/" element={<Map />} />
             <Route path="/login">
              <Route path=":option" element={<Login navigate={navigate}/>} />
              <Route path="" element={<Login navigate={navigate}/>} />
            </Route></>)
    } else { 
      return(
        <Route path="/login">
          <Route path=":option" element={<Login navigate={navigate}/>} />
          <Route path="" element={<Login navigate={navigate}/>} />
        </Route>)
    }
    
  }


  return(
    <Routes>
      {getHome()}
      <Route path="/about" element={<About />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/thanks" element={<Thanks />} />
      <Route path="/mobile" element={<Mobile />} />
      <Route path="/google" element={<Google />} />
      <Route path="/byebye" element={
        <RequireAuth>
          <Byebye />
        </RequireAuth>
      } />
      <Route path="/chat" element={
        <RequireAuth>
          <Chat />
        </RequireAuth>
      } />
      <Route path="/map" element={
        <RequireAuth>
          <Map />
        </RequireAuth>
      } />
      <Route path="/meetexplore" element={
        <RequireAuth>
          <Meetexplore />
        </RequireAuth>
      } />
      <Route path="/ble" element={
        <RequireAuth>
          <Ble />
        </RequireAuth>
      } />
      <Route path="/mymeets" element={
        <RequireAuth>
          <Mymeets />
        </RequireAuth>
      } />
      <Route path="/meet">
          <Route path=":meetId" element={
                    <RequireAuth>
                      <Meet />
                    </RequireAuth>
                    } />
          <Route path="" element={
                    <RequireAuth>
                      <Meet />
                    </RequireAuth>
                    } />
      </Route>
      <Route path="/meetinfos">
          <Route path=":meetId" element={
                    <RequireAuth>
                      <Meetinfos />
                    </RequireAuth>
                    } />
          <Route path="" element={
                    <Oups />
                    } />
      </Route>
      {getLogging()}
      <Route path="/user" element={
        <RequireAuth>
          <User />
        </RequireAuth>
      } />
      <Route path="/settings" element={
        <RequireAuth>
          <Settings />
        </RequireAuth>
      } />
      <Route path="/private" element={
        <RequireAuth>
          <Private />
        </RequireAuth>
      } />
      <Route path="/dashboard" element={
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      } />
      <Route path="/realm">
          <Route path=":realmId" element={
                    <RequireAuth>
                      <Realm />
                    </RequireAuth>
                    } />
      </Route>
      <Route path="/logout" element={
        <Logout />
      } />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<Oups />} />
      
    </Routes>
  );
}

export default Routing
