import React, { useState } from 'react';
import AppService from "../services/app.service";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMe, selectUser } from '../redux/reducers/userSlice.js'

function Private() {

  const [content, setContent] = useState("");
  const reduxuser = useSelector(selectUser);
  const dispatch = useDispatch();

  if (reduxuser.username === undefined) {
    dispatch(fetchMe(),[]);
    return(<>Loading...</>);
  }

  if (content==="") {
      AppService.getContacts().then(
      (response) => {
          setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          setContent(_content);
        console.log(_content);
        if (error.response && error.response.status === 403) {
          console.log(error.response);
        }
      }
    );
  }

  const Display = ({contents}) => {
    if (contents === "") {
      return(<tr></tr>);
    }
    return (<>
        {contents['results'].map(content => (
            <tr key={content.id}><td>{content.name}</td><td>{content.phone}</td><td>{content.email}</td><td>{content.note}</td></tr>
        ))}
    </>);
  }


  return (

    <div className="container">
      <header className="jumbotron">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <Display contents={content} />
          </tbody>
        </table>
      </header>
    </div>
  );
};
export default Private
