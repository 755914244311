import {useContext} from 'react'
import AppService from "../services/app.service";
import { PushNotifications } from "@capacitor/push-notifications";
import { authContext } from '../contexts/AuthContext';
import { Capacitor } from '@capacitor/core';

const MobileNotification = () => {
    const { auth } = useContext(authContext)



    if (auth!==null && auth?.tokens !== null && auth?.tokens !== undefined) {
      PushNotifications.requestPermissions().then(permission => {
        if (permission.receive === "granted") {
          PushNotifications.register();
        }
        else {
          // 
        }
      })
  


      PushNotifications.addListener('registration', (token) => {
        let utoken={"registration_id":token.value, "type":"ios"}
        if (Capacitor.getPlatform() === 'android') {
          utoken={"registration_id":token.value, "type":"android"}
        } 
        AppService.updateFCMDevice(utoken, (results) => {
          if (results.status!==200) {
            // TODO process errors
            console.log("FCMupdate error")
          } else {
            console.log("FCM token updated", token.value)
          }
        });
      })

      PushNotifications.addListener('registrationError', (error) => {
        //
        console.log(JSON.stringify(error))
      })

      PushNotifications.addListener('pushNotificationReceived', (notifications) => {
        console.log(JSON.stringify(notifications))
      })
    }
  }
  


export default MobileNotification
