import React, { createContext, useState, useEffect } from 'react';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import MobileNotification from '../components/MobileNotification';
export const authContext = createContext({});


const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ loading: true, tokens: null });

  const configurePreference = async () => {   
    await Preferences.configure({
      group: 'com.Meeteurs.background.task'
    });
  };

  if (Capacitor.getPlatform()!=="web") {
    configurePreference();
    //MobileNotification()
  } else {
    // Initialize Firebase SDK for web
    /*
    const firebaseConfig = {
      apiKey: "AIzaSyAedRJYVNzF3KK9CwrGaMPye12oUtHx1RY",
      authDomain: "meeters-98bfc.firebaseapp.com",
      projectId: "meeters-98bfc",
      storageBucket: "meeters-98bfc.appspot.com",
      messagingSenderId: "142678078891",
      appId: "1:142678078891:web:54e5ba56af10b4090f0eee",
      measurementId: "G-QRRJ2T6VQC",
    };

    initializeApp(firebaseConfig);
    */
  }
  

  const setName = async (token) => {   
    await Preferences.set({
      key: 'TOKEN',
      value: token,
    });
  };

  const setAuthData = (data) => {
    setAuth({tokens: JSON.stringify(data)});
    if (Capacitor.getPlatform()!=="web") {
      if ('access' in data) { setName(data['access']) } 
    }
  };

  useEffect(() => {
      setAuth({ loading: false, tokens: localStorage.getItem('tokens')});
    }, []);


  return (
    <authContext.Provider value={{ auth, setAuthData }}>
        {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
