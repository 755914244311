import React, { useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { icon, goldIcon, greenIcon, LeafIcon } from "../components/Icons";
import { Button, Box, Stack, Tooltip } from '@mui/material';
import { Geolocation } from '@capacitor/geolocation';
import { useSelector } from 'react-redux';
import { selectUser, updateLocalization } from '../redux/reducers/userSlice.js';
import AppService from "../services/app.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatMessageTimestamp } from '../components/helpers';
import { snackbarContext } from '../contexts/SnackbarContext';
import Control from 'react-leaflet-custom-control'
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PlaceIcon from '@mui/icons-material/Place';
import { useTranslation } from "react-i18next";
import LoginIcon from '@mui/icons-material/Login';
import ChatIcon from '@mui/icons-material/Chat';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { useDispatch } from 'react-redux';

const config = require('../config/default');

function Map() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const reduxuser = useSelector(selectUser);
  let default_lat="48.857322";
  let default_long="2.3376235";
  //console.log(reduxuser)
  if (reduxuser?.profile?.default_lat !== 0) {
    default_lat=reduxuser?.profile?.default_lat
    default_long=reduxuser?.profile?.default_lon
  }
  if (reduxuser?.profile?.lat !== 0) {
    default_lat=reduxuser?.profile?.lat
    default_long=reduxuser?.profile?.lon
  }
  const latitude = React.useRef(default_lat)
  const longitude = React.useRef(default_long)
  const initLatitude = React.useRef(default_lat)
  const initLongitude = React.useRef(default_long)
  const [meetlatitude, setMeetlatitude] = React.useState(default_lat);
  const [meetlongitude, setMeetlongitude] = React.useState(default_long);
  const [days, setDays] = React.useState("14");
  const [meetload, setMeetload] = React.useState(false);
  const [params] = useSearchParams();
  const [markers, setMarkers] = React.useState([]);
  const [shareColor, setShareColor] = React.useState("black")
  const [meeteurs, setMeeteurs] = React.useState([])
  const [meetDetail, setMeetDetail] = React.useState()
  const mapRef = React.useRef(null)
  const [load, setLoad] = React.useState(false)
  const permissions=React.useRef(null)

  const nav=useNavigate()
  const { setSnack } = React.useContext(snackbarContext);
  
  const imageprofil=reduxuser?.profile?.image_url

  let options = {
    enableHighAccuracy: true,
    timeout: 3000,
    maximumAge: 10000,
  };

  
  const checkAppPermissions = async () => {
    try {
      const perm = await Geolocation.checkPermissions()
      if (perm.location === 'granted' || perm.coarseLocation === 'granted') {
        permissions.current=true
      } else {
        permissions.current="unknown"
      }
    }
    catch {
      permissions.current=false
      // Check if we're on Safari and pop up user ?
      setSnack({message:t('map.unsupported_location'), severity:"warning", open:true})
    }
  }

  if (permissions.current===null) {
    checkAppPermissions()
  }

  const getCurrentPosition = async () => {
    //console.log("Geolocation try, on chrome/edge doesn't work sometimes ... !")
    if (permissions.current===true || permissions.current==="unknown" || permissions.current===null) { // try to get positions if permissions allowed or unknown (due to the fact that we're not able to request permissions on web, so we try to get position and read the response error if occurs catching the denied flag)
      Geolocation.getCurrentPosition(options).then((position) => {
          if (Number.isNaN(position.coords.latitude)===false && Number.isNaN(position.coords.longitude)===false) {
            latitude.current=position.coords.latitude
            longitude.current=position.coords.longitude
            dispatch(updateLocalization([position.coords.latitude, position.coords.longitude]))
            getmeets()
          }
      }, err =>{
        console.log(err)
        getmeets()
        if (err.code===1) { // CODE 1 = PERMISSION DENIED, CODE 2 = POSITION UNAVAILABLE, CODE 3 = TIMEOUT
          permissions.current=false
          setSnack({message:t('map.getlocation'), severity:"warning", open:true})
        } else {
          permissions.current=true
        }
        
      });
    }
  };

  if (load===false) {
    // Need to use an hook instead of useEffect who didn't reload when user navigate between different pages
    setLoad(!load)
    getmeets()
    getCurrentPosition()
  }
    
  const setCurrentPosition = (position, error) => {
    if (error?.code===1) { // CODE 1 = PERMISSION DENIED, CODE 2 = POSITION UNAVAILABLE, CODE 3 = TIMEOUT
      permissions.current=false
      setSnack({message:t('map.getlocation'), severity:"warning", open:true})
    } else {
      permissions.current=true
    }
    if (position!==null) {
      if ((position.coords.latitude!==latitude.current) || (position.coords.longitude!==longitude.current)) {
        if (Number.isNaN(position.coords.latitude)===false && Number.isNaN(position.coords.longitude)===false) {
          latitude.current=position.coords.latitude
          longitude.current=position.coords.longitude
          dispatch(updateLocalization([position.coords.latitude, position.coords.longitude]))
        } else {
          //
        }
      }
    }
  }

  useEffect(() => {
    const getlocations = setInterval(() => {
      if (params.get("meetid") !== null) {
        AppService.getMeetLocation(params.get("meetid"), (results) => {
          if (results.status===200) {
            setMeeteurs(results.data.meetmembers)
            //setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          }   
        });

      }
    }, 10000);
    return () => clearInterval(getlocations);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      let data={lon:longitude.current, lat:latitude.current}

      // round the current lat/long to 4 digits to avoid too much updates
      if ((parseFloat(initLatitude.current).toFixed(4)!==parseFloat(latitude.current).toFixed(4)) || (parseFloat(initLongitude.current).toFixed(4)!==parseFloat(longitude.current).toFixed(4))) {
        AppService.patchProfile(data, (results) => {
          if (results.status!==200) {
            //setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          }   
        });
        initLatitude.current=latitude.current
        initLongitude.current=longitude.current
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const flytome = () => {
    permissions.current="unknown" // Reset permissions state
    getCurrentPosition()
    if (mapRef && mapRef?.current) {
      if (Number.isNaN(latitude.current)===false && Number.isNaN(longitude.current)===false) {
        mapRef.current.flyTo([latitude.current, longitude.current])
      }
    }
  };

  const flytomeet = () => {
    if (mapRef && mapRef?.current) mapRef.current.flyTo([meetlatitude, meetlongitude])
  };
  
  const displayRDV = () => {
    if ((params.get("meetid") !== null)) {
      return(
        <Button className="flytomeet" sx={{ color:"black", width: "35px", minWidth: "35px"}} onClick={()=>flytomeet()}>
          <Tooltip 
            title={t('map.rdv')}
            enterTouchDelay={100}
          >
            <PlaceIcon sx={{ color:"black" }}/>
          </Tooltip>
        </Button>
      )
    }
  }
  
  const updateShareLocation = () => {
    let data={"meetmember":{"share_location": false} }
    let newcolor="red"
    if (shareColor!=="green") {
      newcolor="green"
      data.meetmember.share_location=true
    }
    AppService.patchMeet(params.get("meetid"), data, (results) => {
      if (results.status!==200) {
        // TODO process errors
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      } else {
        setShareColor(newcolor)
      }
    });
  }

  const shareLocation = () => {
    if ((params.get("meetid") !== null)) {
      return(
        <Button className="sharelocation" sx={{ color:"black", width: "35px", minWidth: "35px"}} onClick={()=>updateShareLocation()}>
          <Tooltip 
            title={t('map.sharelocation')}
            enterTouchDelay={100}
          >
            <ShareLocationIcon sx={{ color:shareColor }}/>
          </Tooltip>
        </Button>
      )
    }
  }


  const displayChat = () => {
    if ((params.get("meetid") !== null)) {
      return(
        <Button className="gotochat" sx={{ color:"black", width: "35px", minWidth: "35px"}} onClick={()=>nav(`/chat/?meetid=${params.get("meetid")}`)}>
          <Tooltip 
            title={t('map.gotochat')}
            enterTouchDelay={100}
          >
            <ChatIcon/>
          </Tooltip>
        </Button>
      )
    }
  }

  const displayDays = () => {
    if ((params.get("meetid") === null)) {
      return(
        <div>
          <h3 className="maprecherche" style={{color:"black"}}>{t('map.searchfor')}
          <select onChange={(newValue) => {
            setDays(newValue.target.value)
            getmeets(newValue.target.value)
          }} defaultValue="14">
            <option value="1">{t("map.day", { count: 1 })}</option>
            <option value="2">{t("map.days", { count: 2 })}</option>
            <option value="3">{t("map.days", { count: 3 })}</option>
            <option value="7">{t("map.days", { count: 7 })}</option>
            <option value="14">{t("map.days", { count: 14 })}</option>
            <option value="30">{t("map.days", { count: 30 })}</option>
          </select></h3>
        </div>
      )
    }

  }

  function getmeets(d=days) {
    if(mapRef!==null && mapRef?.current) {
      let bounds=mapRef.current.getBounds()
      let max_lat=bounds['_northEast']['lat']
      let min_lat=bounds['_southWest']['lat']
      let max_lon=bounds['_northEast']['lng']
      let min_lon=bounds['_southWest']['lng']


      AppService.getPOI(min_lon, min_lat, max_lon, max_lat, d, params.get("meetid"), (results) => {
        if (results.status===200) {
          if (results?.data!==undefined) {
            let meets=results.data
            setMarkers(meets)
          }
        } else {
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        }
      })  
    }
  }

  function MapBoundaries() {
    useMapEvents({
      dragend: (e) => {
        getmeets()
      },
      zoomend: (e) => {
        getmeets()
      }
    });
  }

  useEffect(() => {
    if (mapRef?.current!==null) {
      flytome()
    }
  }, [mapRef.current]);


  useEffect(() => {
    let id = Geolocation.watchPosition(options, setCurrentPosition);

    return () => Geolocation.clearWatch(id);
  }, [permissions.current]);

  
  if ((params.get("meetid") !== null) && (meetload===false)) {
    AppService.getMeet(params.get("meetid"), (results) => {
      if (results.status!==200) {
        switch (results.response.status) {
          case 404:
            setSnack({message:t('meet.unknown'), severity:"error", open:true})
          break;
          case 403:
            setSnack({message:t('common.forbidden'), severity:"error", open:true})
          break;
          default:
            setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        }
      } else {
        setMeetDetail(<div style={{color:"black"}}><h2>{results.data.name}</h2><h3>{results.data.location}<br/>{formatMessageTimestamp(results.data.date)}</h3></div>)
        let meetmembers=results.data.meetmembers
        
        for (const meetmember of meetmembers) {
          if (meetmember.user.email===reduxuser.email) {
            //console.log(meetmember)
            // Set color for share location
            if (meetmember.share_location===true) {
              setShareColor("green")
            } else {
              setShareColor("red")
            }
          }
        }
        setMeetlongitude(results.data.lon)
        setMeetlatitude(results.data.lat)
      }
      setMeetload(true)
    })
  }


  const join=(meetid) => {
    AppService.joinMeet(meetid, (results) => {
      if (results.status===208) {
        setSnack({message:t('meet.already_joined'), severity:"info", open:true})
      } 
      else if (results.status===403) {  
        setSnack({message:t('common.forbidden'), severity:"error", open:true})
      }
      else if (results.status!==201) {
        // TODO process errors
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      } else {
        // Navigate to mymeet
        nav(`/mymeets/`);
      }
    });      
  }

  const leave=(meetid) => {
    AppService.leaveMeet(meetid, (results) => {
      if (results.status!==204) {
        // TODO process errors
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      } else {
        // Refresh meet points
        getmeets()
      }
    });      
  }

  const chat=(meetid) => {
    nav(`/chat/?meetid=`+meetid);     
  }
  let imgprofil=imageprofil
  if (imageprofil===null) {
    imgprofil=config.media.endpoint+"/media/static/question_icon.png"
  }
  var myiconurl="<div style='background-color:#000' class='marker-pin'></div><img style='color:#fff' src='" + imgprofil + "' class='marker-img'>"
  var myicon = new LeafIcon({html: myiconurl})

  return (
    <>
      <MapContainer center={[initLatitude.current, initLongitude.current]} zoom={10} id="map" key="1234" style={{ height: "100%", minHeight: "100%" }} ref={mapRef}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker id="12" key="1" position={[latitude.current, longitude.current]} icon={myicon}>
          <Popup>
            {t('map.mylocation')}<br />
          </Popup>
        </Marker>
        {meeteurs.map((meeteur, index) => {
          let profilurl="<div style='background-color:#708090;' class='marker-pin'></div><img alt='" + meeteur.user.username + "' src='" + config.media.endpoint+meeteur.user.profile.image_url + "' class='marker-img'>"
          let meeteuricon = new LeafIcon({html: profilurl})
          return(
            <Marker id={index} key={index} position={[meeteur.user.profile.lat, meeteur.user.profile.lon]} icon={meeteuricon}>
              <Popup>
                {meeteur.user.username}<br/>
                {t('map.lastupdate')}{formatMessageTimestamp(meeteur.user.profile.location_date)}
              </Popup>
            </Marker>
          )
        })}

        {/* Add dynamic makers */}
        <MarkerClusterGroup
          chunkedLoading
        >
        {markers.map((marker, index) => {
          let taglist="";
          if (marker.imember===true && marker.iadmin===true) {
            return(
              <Marker id={index} key={index} position={[marker.lat, marker.lon]} icon={goldIcon}>
                <Popup>
                  {marker.tags.forEach(tag => {
                    taglist=taglist+" "+tag.name 
                  })}

                  <b>{marker.name}</b><br/>{formatMessageTimestamp(marker.date)}<br/>{t('common.meeteurs')}: {marker.members_count} / {marker.max_members} <br/>{t('meet.tags_short')}:{taglist}<br/><br/>
                  <Box display="flex" justifyContent="center"><Button sx={{mx:1}} variant="contained" color="primary" onClick={() => { chat(marker.id); }}><ChatIcon/></Button></Box>
                </Popup>
              </Marker>
            )
          } else if (marker.imember===true && marker.iadmin===false) {
            return(
              <Marker id={index} key={index} position={[marker.lat, marker.lon]} icon={greenIcon}>
                <Popup>
                  {marker.tags.forEach(tag => {
                    taglist=taglist+" "+tag.name 
                  })}

                  <b>{marker.name}</b><br/>{formatMessageTimestamp(marker.date)}<br/>{t('common.meeteurs')}: {marker.members_count} / {marker.max_members} <br/>{t('meet.tags_short')}:{taglist}<br/><br/>
                  <Box display="flex" justifyContent="center"><Button sx={{mx:1}} variant="contained" color="primary" onClick={() => { chat(marker.id); }}><ChatIcon/></Button><Button sx={{mx:1}} variant="contained" color="primary" onClick={() => { leave(marker.id); }}><LogoutIcon/></Button></Box>
                </Popup>
              </Marker>
            )
          }
          else {
            return(
              <Marker id={index} key={index} position={[marker.lat, marker.lon]} icon={icon}>
                <Popup>
                  {marker.tags.forEach(tag => {
                    taglist=taglist+" "+tag.name 
                  })}

                  <b>{marker.name}</b><br/>{formatMessageTimestamp(marker.date)}<br/>{t('common.meeteurs')}: {marker.members_count} / {marker.max_members} <br/>{t('meet.tags_short')}:{taglist}<br/><br/>
                  <Box display="flex" justifyContent="center"><Button sx={{mx:1}} variant="contained" color="primary" onClick={() => { join(marker.id); }}><LoginIcon/></Button><Button sx={{mx:1}} variant="contained" color="primary" onClick={() => { nav("/meetinfos/"+marker.id); }}><InfoIcon/></Button></Box>
                </Popup>
              </Marker>
            )
          }
        })}
        </MarkerClusterGroup>
        <MapBoundaries />
          <Control position='bottomleft'>
            <Stack direction='column' spacing={0} >
              <Button className="flytome" sx={{color:'black'}} style={{ width: "35px", minWidth: "35px"}} onClick={()=>flytome()}>
                <Tooltip 
                  title={t('map.me')}
                  enterTouchDelay={100}
                >
                  <MyLocationIcon />
                </Tooltip>
              </Button>  
              {shareLocation()}
              {displayRDV()}
              {displayChat()}
              {displayDays()}
            </Stack>
          </Control>
          <Control position='topright'>
            {meetDetail}
          </Control>
      </MapContainer>
    </>

  );
}

export default Map
