import React, { useState, useContext } from 'react';
import { Box, Typography, Divider, Container, TextField, Button } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/reducers/userSlice.js'
import { snackbarContext } from '../contexts/SnackbarContext';
import AppService from '../services/app.service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from "react-i18next";
import { dialogContext } from '../contexts/DialogContext';


function DashboardRealms({setGlobalLoad}) {
  const { t } = useTranslation();
  const { setSnack } = useContext(snackbarContext);
  const { setDialog } = useContext(dialogContext);
  const reduxuser = useSelector(selectUser);
  let navigate = useNavigate();
  const [realms, setRealms] = useState([]);
  const [load, setLoad] = useState(false)
  const [newrealm, setNewrealm] = useState("")

  React.useEffect(() => {
    if (reduxuser?.type!=="P") {
      navigate("/");
    }
  }, [reduxuser])

  if (load===false) {
    AppService.getRealms((results) => {
      if (results.status!==200) {
        setLoad(!load)
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      } else {
        let temp=[]
        for (const i in results.data) { // Only display realms where the user is admin
          if (results.data[i].isadmin===true) {
            temp.push(results.data[i])
          }
        }
        setRealms(temp)
        setLoad(!load)
      }
    })
  }
  //console.log(realms)
  const editRealm=(id, type, newvalue) => {
    let newrealms=[]
    for (const i in realms) {
      let temp=[]
      if (realms[i].id===id) {
        temp=realms[i]
        if (type==="edit") {
          temp.edit=true
        } else {
          temp.name=newvalue
        }
      } else {
        temp=realms[i]
        temp.edit=false
      }
      newrealms.push(temp)
    }
    setRealms(newrealms)
  }

  const createRealm=() => {
    if (newrealm.length===0) {
      setSnack({message:t('realm.name_required'), severity:"error", open:true})
    } else {
      let data={
        "name":newrealm
      }
      AppService.createRealm(data, (results) => {
        if (results.status!==201) {
          // TODO process errors
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        } else {
          setNewrealm("")
          setLoad(false)
          setGlobalLoad(true)
        }
      });

    }
  
  }

  const deleteRealm=(id) => {
    AppService.deleteRealm(id, (results) => {
      if (results.status!==204) {
        // TODO process errors
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      } else {
        setLoad(false)
        setGlobalLoad(true)
      }
    });
  }

  const validateRealm=(id) => {
    for (const i in realms) {
      if (realms[i].id===id) {
        let patchdata={
          "name":realms[i].name
        }
        //console.log(realms[i])
        AppService.patchRealm(id, patchdata, (results) => {
          if (results.status!==200) {
            // TODO process errors
            setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          } else {
            setLoad(false)
          }
        });
      }
    }
  }

  const cancelDelete=()=>{
    //
  }

  const displayRealm=(realm,idx) => {
    if (realm?.edit===true) {
      return(<Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'left'
        }}
        key={idx}
        >
          <TextField
                  fullWidth
                  required
                  inputProps={{ maxLength: 80 }}
                  value={realm.name}
                  onChange={(newValue) => {
                    editRealm(realm.id, "", newValue.target.value);
                  }}
                  id={idx}
                  label={t('realm.edit')}
                  name={realm.name}
                  variant="outlined"
                />
          <Button
              variant="contained"
              onClick={()=> {validateRealm(realm.id)}}
              sx={{ml:1}}
          >
            OK
          </Button>
        </Box>)
    } else {
      return(<Box
        sx={{
          margin:2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'left'
        }}
        key={idx}
        >
          <Typography width='100%'>{realm.name}</Typography>
          <EditIcon onClick={() => {editRealm(realm.id, "edit", "")}} />
          <SettingsIcon onClick={() => {navigate("/realm/"+realm.id)}} />
          <DeleteIcon onClick={() => {
              setDialog({open:true, title:t('common.areusure'), message:[t('realm.delete1'), t('realm.delete2')], okButton:t('common.ok'), cancelButton:t('common.cancel'), callback:()=>{deleteRealm(realm.id)}, cancelCallback:cancelDelete})
            }  
          } />
        </Box>)
    }
  }

  return (
        <Container maxWidth="sm" sx={{ marginTop:2 }} >
          <Typography variant="h6">
          {t('realm.manage_title')}
          </Typography>
          <Typography variant="h8">
            {t('realm.description_1')}<br/>
            {t('realm.description_2')}<br/>
          </Typography>
          <Divider/>
            <Box
              sx={{
                margin:2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'left',
              }}
              >
              <TextField
                  fullWidth
                  required
                  value={newrealm}
                  onChange={(newValue) => {
                    setNewrealm(newValue.target.value);
                  }}
                  inputProps={{ maxLength: 80 }}
                  id="newrealm"
                  label={t('realm.new')}
                  name="newrealm"
                  variant="outlined"
                />
              <Button
                variant="contained"
                onClick={()=> {createRealm()}}
                sx={{ml:1}}
              >
                {t('realm.go')}
              </Button>
            </Box>
          <Divider/>
          {realms.map((realm,idx) => (
              displayRealm(realm, idx)
          ))}
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
          >
            <Button
                variant="contained"
                onClick={()=> {navigate(-1)}}
                sx={{ml:1}}
            >
              {t('common.ok')}
            </Button>
          </Box>
        </Container>
      
  );
}

export default DashboardRealms
