import React from 'react';
import { Tooltip, Avatar } from '@mui/material';

const config = require('../config/default');

const ImageProfile = (username, first_name, last_name, image_url, idx) => {
  function imageprofile(image_url) {
    var image="/"
    if (image_url!==null) {
      image=config.media.endpoint+image_url
    }
    return image
  }

  return (
      <Tooltip 
        title={username}
        enterTouchDelay={100}
      >
        <Avatar sx={{mr:1}} alt={username.toLowerCase()} src={imageprofile(image_url)}>{first_name.charAt(0).toLowerCase() + last_name.charAt(0).toLowerCase() }</Avatar>
      </Tooltip>
  )
  
}


export {
  ImageProfile
}
  