import React, { useEffect } from "react";
import GoogleButton from 'react-google-button';


export default function Google(props) {

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id:"619047551698-1v1q8ldf5fs8doc4mfnqahmcdecpgcj4.apps.googleusercontent.com",
      callback: googleButton
    });
  
    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }
    )

  }, []);

  async function googleButton(response) {
    console.log("hello")
    //console.log(response)
  }

  return (
    <>    

          <div id="signInDiv"></div>

          <GoogleButton
          label="Sign in with Google"
          onClick={() => { googleButton() }}
          style={{width:'100vl', maxWidth:'100vl'}}
        />
    </>
  );
}
