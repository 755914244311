import React, {useContext} from 'react'
import { useTranslation } from "react-i18next";
import { Box, Typography, Container, Button } from '@mui/material';
import AppService from '../services/app.service';
import { useNavigate } from 'react-router-dom';
import { dialogContext } from '../contexts/DialogContext';
const config = require('../config/default');

function Byebye() {
  const MEDIA_URL = config.media.endpoint;
  const { t } = useTranslation();
  const { setDialog } = useContext(dialogContext);

  let navigate = useNavigate();
  const logoutLink = () => {
    navigate("/logout");
  }
 
  const cancelDelete=()=>{
    //
  }

  const deleteaccount=() => {
    AppService.deleteAccount((result)=> {
      let a=result?.status
      if (a==204) {
        logoutLink()
      }
    })
  }

  return (
    <Box
    sx={{
      margin:2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      minHeight: 'calc(100vh + 150px)'
    }}
    >
      <Container maxWidth="sm" sx={{ marginTop:5 }} >
        <Typography variant="h6" sx={{ mb:2 }}>
          {t('byebye.title')}
        </Typography>
        {t('byebye.text')}<br/>
        <Box
          sx={{
            m:5,
            display:'flex',
            justifyContent:'center'
          }}
          >
          <Button variant="contained" onClick={()=>{
              setDialog({open:true, title:t('common.areusure'), message:[t('user.delete')], okButton:t('common.ok'), cancelButton:t('common.cancel'), callback:deleteaccount, cancelCallback:cancelDelete})
            }
          }>{t('common.ok')}</Button>
          
        </Box>
      </Container>
    </Box> 
  );
}

export default Byebye
