import React, { useEffect, useState, useContext } from 'react';
import AppService from "../services/app.service";
import { useNavigate } from "react-router-dom";
import { MeetCard, NewMeetCard } from '../components/MeetCard';
import { Alert, Typography, Box, Container, Divider } from '@mui/material';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import { useTranslation } from 'react-i18next';
import { snackbarContext } from '../contexts/SnackbarContext';
import { dialogContext } from '../contexts/DialogContext';

function Mymeets() {
  const { setSnack } = useContext(snackbarContext);
  const { setDialog } = useContext(dialogContext);
  const { t } = useTranslation();
  const [content, setContent] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (reload===false) {
      AppService.getMyMeets().then(
        (response) => {
          setContent(response.data);
          setReload(!reload)
        },
        (error) => {
          <Alert severity="error">Try again later... :(</Alert>
          setReload(!reload)
        }
      );
    }
  }, [reload]);


  const nav=useNavigate();
  if (content === "") {
    return(<></>);
  } else {
    return (
      <Container maxWidth="xl">
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ModeOfTravelIcon />
        <Typography component="h1" variant="h5">
          {t('meet.title')}
        </Typography>
        <Divider />
        <Box sx={{ pt:2, display: 'grid', gap: 1, gridTemplateColumns: { md:'repeat(2, 1fr)', lg:'repeat(3, 1fr)', xl:'repeat(4, 1fr)'} }}>
            {content.map((meet,idx) => (
              MeetCard(meet, idx, nav, setReload, reload, setSnack, t, setDialog)
              //console.log(meet)
            ))}
            {NewMeetCard(nav, t)}
        </Box>
      </Box>
      </Container>
    );
  }
};
export default Mymeets
