import React, { createContext, useState } from 'react';

export const appsettingsContext = createContext({});

const AppsettingsProvider = ({ children }) => {
  const [appsettings, setAppsettings] = useState({ Mode: "dark" });

  return (
    <appsettingsContext.Provider value={{ appsettings, setAppsettings }}>
        {children}
    </appsettingsContext.Provider>
  );
};

export default AppsettingsProvider;
