import React from 'react'
import { authContext } from "../contexts/AuthContext";
import { useDispatch } from 'react-redux';
import { userLogout } from '../redux/reducers/userSlice.js';
import { useNavigate } from 'react-router-dom';


function Logout() {
  const dispatch = useDispatch();
  const { setAuthData } = React.useContext(authContext)
  let nav=useNavigate();

  React.useEffect(() => {
     
      setAuthData([])
      localStorage.removeItem("refresh");
      localStorage.removeItem("tokens");
      localStorage.removeItem("access");
      localStorage.removeItem("user");
      localStorage.removeItem("persist:root");
      dispatch(userLogout());
      setTimeout(nav('/'), 2000);

      
    }
  )



  return (
    <>Redirecting...</>
  );
}

export default Logout
