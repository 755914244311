import React, { useState, useContext } from "react";
import { Button, TextField, Box, Typography, Container } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch } from "@mui/material";
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Location from './Location';
import Tags from './Tags';
import AppService from "../services/app.service";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { snackbarContext } from '../contexts/SnackbarContext';
import { useTranslation } from "react-i18next";

export default function DashboardCreateMeet({globalLoad, setGlobalLoad}) {
  const { t } = useTranslation();
  const { setSnack } = useContext(snackbarContext);
  const [load, setLoad] = useState(false);
  const [location, setLocation] = useState("");
  const [tags, setTags] = useState(["OpenToTalk"])
  const [value, setValue] = useState(Date.now);
  const [meetname, setMeetname] = useState("");
  const [advance, setAdvance] = useState(true);
  const [joined, setJoined] = useState(false);
  const [meeteurs, setMeeteurs] = useState(6);
  const [currentrealm, setCurrentrealm] = useState(1);
  const [realms, setRealms] = useState([]);
  const readonly = React.useRef(false)
  

  if (globalLoad===true) {
    setGlobalLoad(false)
    AppService.getRealms((results) => {
      if (results.status!==200) {
        // TODO process errors
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      } else {
        setRealms(results.data)
      }
    })
  }


  if (load!==true) {
    setLoad(true)
    AppService.getRealms((results) => {
      if (results.status!==200) {
        // TODO process errors
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      } else {
        setRealms(results.data)
      }
    })
  }

  const displayRealm=(realm) => {
    return(<MenuItem value={realm.id}>{realm.name}</MenuItem>)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
   
    //const data = new FormData(event.currentTarget);
    let meetdate=new Date(value).toISOString()
    let meetlocation=location.label
    let meettags=tags
    let maxmembers=meeteurs
    let joinmeet=joined
    let newmeet={
      "name":meetname,
      "joined":joinmeet,
      "location":meetlocation,
      "date":meetdate,
      "update_tags":meettags,
      "max_members":maxmembers
    }
    if (meetname==="" || meetdate==="" || meettags.length===0 || meetlocation===undefined) {
      //console.log(meetname + " " + meetdate +  " " + meettags + " " + meetlocation)
      setSnack({message:t('meet.all_fields_required'), severity:"warning", open:true})
    } else {
      AppService.createMeet(newmeet, (results) => {
        if (results.status!==201) {
          // TODO process errors
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        } else {
          setLoad(false)
          setSnack({message:t('meet.created'), severity:"success", open:true})
        }

      });
      
    }
  };

  const displayAdvance = () => {
    if (advance===false) {
      return(  
      <>    
        <Box sx={{ gridColumn: "span 20", display:"flex", alignItems:"center"}} style={{ cursor: 'pointer' }} onClick={()=>{setAdvance(true)}}>{t('common.options')}<ArrowDropDownIcon/></Box>
      </>
      )
    } else {
      return(  
        <>    
          <Box sx={{ gridColumn: "span 20", display:"flex", alignItems:"center"}} style={{ cursor: 'pointer' }} onClick={()=>{setAdvance(false)}}>{t('common.options')}<ArrowDropUpIcon/></Box>
            <FormControl sx={{ mt:"1", gridColumn: "span 20", alignItems:"center" }} >
              <FormControlLabel
                control={
                  <Switch checked={joined} onChange={()=>{setJoined(!joined)}} name="joinmeet" />
                }
                label={t('meet.join_yourself')}
              />
            </FormControl>
            <FormControl sx={{ mt:"1", gridColumn: "span 20" }} >
              <InputLabel id="maxmeeteurs">{t('meet.max_meeteurs')}</InputLabel>
              <Select
                labelId="maxmeeteurs-label"
                id="maxmeeteurs-select"
                value={meeteurs}
                readOnly={readonly.current}
                onChange={(newValue) => {
                  setMeeteurs(newValue.target.value);
                }}
              >
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mt:"1", gridColumn: "span 20" }} >
              <InputLabel id="realms">{t('common.realms')}</InputLabel>
              <Select
                labelId="realms-label"
                id="realms-select"
                value={currentrealm}
                readOnly={readonly.current}
                onChange={(newValue) => {
                  setCurrentrealm(newValue.target.value);
                }}
              >
                {realms.map((realm, index) => (
                  displayRealm(realm)
                ))}
              </Select>
            </FormControl>
        </>
        )
    }
  }


  return (
      <Container maxWidth="sm">
        <Box
          sx={{
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h5">
          <ModeOfTravelIcon />{t('meet.generate')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ m: 1, alignItems:'center', display: 'grid', gap: 1, gridTemplateColumns: 'repeat(20, 1fr)' }}>
            <TextField
              sx={{ mt:1, gridColumn: "span 20" }}
              required
              value={meetname}
              onChange={(newValue) => {
                setMeetname(newValue.target.value);
              }}
              inputProps={{ maxLength: 200 }}
              fullWidth
              id="name"
              label={t('meet.purpose')}
              name="name"
              variant="outlined"
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                ampm={false}
                disabled={readonly.current}
                label={t('meet.when')}
                inputFormat="DD/MM/yyyy HH:mm"
                renderInput={(params) => <TextField id="datetime" variant="outlined" required fullWidth {...params} sx={{ gridColumn: "span 20" }}  />}
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
            />
            </LocalizationProvider>
            <Location readonly={readonly.current} label={t('meet.where')} value={location} setValue={setLocation} sx={{ mt:"1", gridColumn: "span 19" }} />
            <Tags label={t('meet.tags')} tags={tags} setTags={setTags} sx={{ gridColumn: "span 20" }}/>
            {displayAdvance()}
            <Box display="flex" justifyContent="center" sx={{ gridColumn: "span 20"}}>
              <Button
                type="submit"
                variant="contained"
              >
                {t('meet.go')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
  );    
}
