import React, { useContext } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import { debounce } from '@mui/material/utils';
import AppService from "../services/app.service";
import { Geolocation } from '@capacitor/geolocation';
import MyLocation from '@mui/icons-material/MyLocation';
import { snackbarContext } from '../contexts/SnackbarContext';
import { useTranslation } from "react-i18next";

export default function Location({value, setValue, sx, label, readonly}) {
  if (label===undefined) { label="Add a location *" }
  if (readonly===undefined) { readonly = false }
  //const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const { t } = useTranslation();
  const { setSnack } = useContext(snackbarContext);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        AppService.searchLocation(request.input, callback);
        
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  // Geolocation
  const getCurrentPosition = async () => {

    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    let error=false
    if (readonly===false) {
      const coordinates = await Geolocation.getCurrentPosition(options=options).catch(err => { error=true })
      if (coordinates?.coords?.longitude!==undefined && coordinates?.coords?.latitude!==undefined && error===false) {
        // Retrieve closest location
        AppService.getAddressFromCoordinates(coordinates.coords.longitude, coordinates.coords.latitude, (results) => {
          let result=results.data
          if (result[0]?.label!==undefined){
            setValue(result[0])
            setSnack({message:t('common.gpsok'), severity:"info", open:true})
          }
        })
      } else {
        // Send a notification
        setSnack({message:t('common.gpserror'), severity:"warning", open:true})
      }
    }
  };


  return (
    <>
    <Box sx={sx}>
    <Autocomplete
      id="location-picker"
      className='defaultlocation'
      filterOptions={(x) => x} // Override filter option (no filtering from autocomplete component)
      options={options}
      autoComplete
      readOnly={readonly}
      fullWidth
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}

      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => {
        const matches =
          option || [];

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>

                  <Box
                    key={matches}
                    component="span"
                    sx={{ fontWeight: matches.highlight ? 'bold' : 'regular' }}
                  >
                    {matches.label}
                  </Box>
              </Grid>
            </Grid>            
          </li>
        );
      }}
    />
    </Box>
    <Box sx={{gridColumn:"span 1", alignItems:"center"}}>
        <MyLocation
          style={{ fontSize: 30, marginLeft: 0}}
          className='autolocation'
          onClick={getCurrentPosition}
        />
    </Box>
    </>
  );
}