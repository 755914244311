import React from 'react';
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from "react-router-dom";
import './i18n';
import './css/TopNavBar.css'
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './App';
import AuthProvider from './contexts/AuthContext';
import reportWebVitals from './reportWebVitals';
import store from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { Provider } from 'react-redux'
import { defineCustomElements } from '@ionic/pwa-elements/loader'; // needed for capacitor camera API
import AppsettingsProvider from './contexts/AppsettingsContext';

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  /* <React.StrictMode> */
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <AppsettingsProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppsettingsProvider>
      </AuthProvider>
    </PersistGate>
  </Provider>

  /* </React.StrictMode> */
);
defineCustomElements(window);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
