import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ImageProfile } from './ImageProfile';
import { formatMessageTimestamp } from "./helpers";

const Message = (user, message, idx) => {
  function itsme(username){
      if (user===username) {
          return true;
      } else {
          return false;
      }
  }

  function content(message) {
      if (message===""){
          return <Typography fontWeight="regular" fontStyle="italic">DELETED</Typography>
      }
      return <Typography fontWeight="regular">{message}</Typography>
  }
  if (message.author.email!=="bot") {
    return (
        <Box sx={{display: 'flex', padding:0.2, justifyContent:itsme(message.author.username) ? 'flex-start':"flex-end"}} key={idx}>
            {ImageProfile(message.author.username, message.author.first_name, message.author.last_name, message.author.profile.image_url)}
            <Box sx={{ display: 'inline', textAlign: 'left', padding:1, backgroundColor: itsme(message.author.username) ? 'warning.dark': 'secondary.main', borderRadius:3}}>
                {content(message.content)}
                <Typography fontWeight="light" variant="subtitle2" textAlign="right">{message.author.username} - {formatMessageTimestamp(message.date)}</Typography>
            </Box>
        </Box>
    )
  } else {
    return (
      <Box sx={{display: 'flex', padding:0.2 }} key={idx}>
          {ImageProfile(message.author.username, "b", "o", message.author.profile.image_url)}
          <Box sx={{ display: 'inline', textAlign: 'left', padding:1, backgroundColor: 'primary.main', borderRadius:3, width:"100%"}}>
              {content(message.content)}
              <Typography fontWeight="light" variant="subtitle2" textAlign="right">{message.author.username} - {formatMessageTimestamp(message.date)}</Typography>
          </Box>
      </Box>
    )
  }
}


export {
    Message
}
  