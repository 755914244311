import React, { useState, useContext } from 'react';
import { Switch, Box, FormGroup, FormControlLabel, FormControl, Container, Button, Typography, Chip, Divider, Select, MenuItem } from '@mui/material';
import AppService from '../services/app.service';
import { useNavigate } from "react-router-dom";
import { snackbarContext } from '../contexts/SnackbarContext';
import Location from '../components/Location';
import { DisplayRealm } from '../components/DisplayRealm';
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  const { setSnack } = useContext(snackbarContext);
  const [load, setLoad] = useState(false);
  const [location, setLocation] = useState("");
  const [radius, setRadius] = useState(25);
  const [defaultlocnotif, setDefaultlocnotif] = useState(false)
  const [newsletter, setNewsletter] = useState(false)
  const [locationshare, setLocationshare] = useState(false)  
  const [notifications, setNotifications] = useState([])
  const [realms, setRealms] = useState(new Map())

  let nav=useNavigate();

  if (location !=="" && location!==null) {
    let v={}
    v['label']=location['label']
    v['radius']=radius
    setNotifications([v, ...notifications])
    setLocation("")
  }

  const handleSubmit = () => {
    let notificationArray=[]
    for (var i = 0; i < notifications.length; i++) {
      notificationArray.push({"location": notifications[i]['label'], "radius": notifications[i]['radius']});
    }
    let realmsArray=[]
    Array.from(realms).map((realm,idx) => {
      realmsArray.push(realm[0])
      return(realmsArray)
    })

    let data={
      "newsletter":newsletter,
      "default_notif":defaultlocnotif,
      "location_share":locationshare
    }
    data["notifications"]=notificationArray
    data["realms"]=realmsArray
    AppService.patchProfile(data, (results) => {
      if (results.status!==200) {
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      } else {
        nav(-1);
      }

    });


  }
  

    if (load===false) {
      AppService.getMe((results) => {
        if (results.status!==200) {
          // TODO process errors
          setLoad(true)
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          nav(-1);
        } else {
          setLoad(true)
          setNewsletter(results.data.profile.newsletter)
          setLocationshare(results.data.profile.location_share)
          setDefaultlocnotif(results.data.profile.default_notif)
          let n=[]
          let notificationArray=results.data.profile.notifications
          for (var i = 0; i < notificationArray.length; i++) {
            let v={}
            v['label']=notificationArray[i].location
            v['radius']=notificationArray[i].radius
            n.push(v);        
          }
          setNotifications(n)
          
          let realmArray=results.data.realms
          for (i = 0; i < realmArray.length; i++) {
            setRealms(new Map(realms.set(String(realmArray[i].name), { dialog:false, isadmin:realmArray[i].isadmin, key:realmArray[i].id } )))
          } 
        }
      })
    }

    if (load===false) {
      return (<>Loading...</>)
    } else {
      return(
        <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'calc(100vh + 150px)'
          }}
        >
          <Typography component="h1" variant="h5" marginBottom="10px">
            {t('settings.title')}
          </Typography>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch className='notifdefaultloc' checked={defaultlocnotif} onChange={()=>{setDefaultlocnotif(!defaultlocnotif)}} name="notifdefaultlocation" />
                }
                label={t('settings.notifications_defaultloc')}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch className='newsletter' checked={newsletter} onChange={()=>{setNewsletter(!newsletter)}} name="newsletter" />
                }
                label={t('settings.newsletter')}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch disabled checked={false} onChange={()=>{setLocationshare(!locationshare)}} name="locatinshare" />
                }
                label={t('settings.sharemylocation')}
              />
            </FormGroup>
          </FormControl>
          <Divider />
          <Box sx={{width:'100%', mt:2}}>
            <Typography component="body">
              {t('settings.notifications_title')}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" sx={{ alignItems:'center', display: 'grid', gap:1, gridTemplateColumns: 'repeat(20, 1fr)', mb:3 }}>
            <Select
              sx={{ mt:"1", gridColumn: "span 20" }}
              labelId="kmaround"
              className='notifaround'
              id="kmaround-select"
              value={radius}
              onChange={(newValue) => {
                setRadius(newValue.target.value);
              }}
            >
              <MenuItem value={1}>1km {t('settings.around')}</MenuItem>
              <MenuItem value={5}>5km {t('settings.around')}</MenuItem>
              <MenuItem value={10}>10km {t('settings.around')}</MenuItem>
              <MenuItem value={25}>25km {t('settings.around')}</MenuItem>
              <MenuItem value={50}>50km {t('settings.around')}</MenuItem>
            </Select>
            <Location label={t('settings.location')} value={location} setValue={setLocation} sx={{ mt:"1", gridColumn: "span 19" }} />
          </Box>
          <Box sx={{width:'100%', mt:2}}>
            <Typography component="body" className='notiflist'>
              {t('settings.notifications_list')}
            </Typography>
          </Box>
          <Box>
            {notifications.map((notif,idx) => (
                <Chip key={idx} sx={{m:1}} label={notif.label + " (" + notif.radius + "km " + t('settings.around') + ")"} onDelete={()=>{
                  const myArray=notifications.slice() // slice needed to made a copy of the array
                  const index = myArray.indexOf(notif);
                  myArray.splice(index, 1);
                  setNotifications(myArray)
                }} />
            ))}
          </Box>
          <Divider />
          <Typography className='realmlist' variant="body" sx={{mt:3}}>
            {t('settings.realms')}
          </Typography>
          <Box>
            {Array.from(realms).map((realm) => (
              DisplayRealm(realms, setRealms, realm)
            ))}
          </Box>
          <Box> 
            <Button
                variant="contained"
                onClick={()=> {nav(-1)}}
                sx={{mt:1, mr:1}}
              >
                {t('common.cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{mt:1, mr:1}}
                onClick={()=> {handleSubmit()}}
              >
                {t('common.save')}
              </Button>
            </Box>
        </Box>
      </Container>
      );
    }

};

export default Settings;
