import React, { useState, useContext } from "react";
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import Location from '../components/Location';
import AppService from "../services/app.service";
import { useNavigate } from "react-router-dom";
import { MeetCard, NewMeetCard } from '../components/MeetCard';
import { Grid, Box, Divider, Button, Typography, Container, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/reducers/userSlice.js';
import { snackbarContext } from '../contexts/SnackbarContext';
import { dialogContext } from "../contexts/DialogContext.js";
import { useTranslation } from "react-i18next";

export default function Meetexplore(props) {
  const { t } = useTranslation();
  const { setSnack } = useContext(snackbarContext);
  const reduxuser = useSelector(selectUser);
  const [location, setLocation] = useState({label:reduxuser.profile.location});
  const [message, setMessage] = useState("")
  const [content, setContent] = useState("");
  const [reload, setReload] = useState(false);
  const [distance, setDistance] = useState(10);
  const [nextpage, setNextpage] = useState(null)
  const { setDialog } = useContext(dialogContext);
  const title=t('meet.find')
  const nav=useNavigate();

  const handleSubmit = (event) => {
    setMessage("");
    //console.log(location)
    event.preventDefault();
    if (location===undefined || location===null || location?.label==="") {
      setSnack({message:t('meet.location_required'), severity:"error", open:true})
    } else {     
      // Search meets
      let place=location.label
      AppService.searchMeet(place, distance, null, (results) => {
        if (results.status!==200) {
          // TODO process errors
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        } else {
          if (results.data.results.length===0) {
            setMessage(<Grid container justifyContent="center">
                           <Button variant="contained" sx={{m:1}} onClick={()=>{nav("/meet")}}>{t('meet.add')}</Button>
                           <Button variant="contained" sx={{m:1}} onClick={()=>{nav("/settings")}}>{t('meet.subscribe')}</Button>
                        </Grid>)   
                        
          }
          setContent(results.data.results)
          setNextpage(results.data.next)
          
        }

      });

    }
  }

  const displayContent = (content) => {
    if (content === "") {
      return(
        <Box sx={{ pt:2, display: 'grid', gap: 1, gridTemplateColumns: { md:'repeat(2, 1fr)', lg:'repeat(3, 1fr)', xl:'repeat(4, 1fr)'} }}>
          {NewMeetCard(nav, t)}
        </Box>
      )
    } else {
      return (
        <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Divider />
        <Box sx={{ pt:2, display: 'grid', gap: 1, gridTemplateColumns: { md:'repeat(2, 1fr)', lg:'repeat(3, 1fr)', xl:'repeat(4, 1fr)'} }}>
            {content.map((meet,idx) => (
              MeetCard(meet, idx, nav, setReload, reload, setSnack, t, setDialog)
              //console.log(meet)
            ))}
            {NewMeetCard(nav, t)}
        </Box>
      </Box>
      );
    }
  }

  const loadMore = () => {
    if (nextpage) {
      return(
        <Button variant="contained" sx={{m:1}} onClick={()=>{
          AppService.followGetLink(nextpage, (results) => {
            if (results.status!==200) {
              // TODO process errors
              setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
            } else {
              let newcontent=[...content, ...results.data.results]
              setContent(newcontent)
              setNextpage(results.data.next)
              //console.log(nextpage)
            }
          })

        }}>{t('common.loadmore')}</Button>
      )
    }
  }


  return (
    <Container maxWidth="xl">
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ModeOfTravelIcon />
      <Typography component="h1" variant="h5">
        {title}
      </Typography>
      {message}
      <Divider />
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ m: 1, alignItems:'center', display: 'grid', gap: 1, gridTemplateColumns: 'repeat(20, 1fr)' }}>
        
        <Location label={t('meet.where')} value={location} setValue={setLocation} sx={{mt:"1", gridColumn: {xs:"span 19", lg: "span 9", xl:"span 8"}}}/>

        { /* <NewTag tags={tags} setTags={setTags} sx={{ ml:1 }}/> */ }
        <FormControl sx={{ mt:"1", gridColumn: {xs:"span 20", lg: "span 10", xl:"span 8"} }} >
            <InputLabel id="demo-simple-select-label">{t('meet.distance')}</InputLabel>
            <Select
              className="maxdistance"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={distance}
              onChange={(newValue) => {
                setDistance(newValue.target.value);
              }}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
        </FormControl>
        <Box display="flex" justifyContent="center" sx={{ gridColumn: {xs:"span 20", lg: "span 19", xl:"span 3"}}}>
          <Button
            type="submit"
            variant="contained"
          >
            {t('meet.search')}
          </Button>
        </Box>
      </Box>
    </Box>
    {displayContent(content)}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {loadMore()}
    </Box>
  </Container>

  );
}
