import React from 'react'
import { Box, Typography, Container } from '@mui/material';
const config = require('../config/default');

function Privacy() {
  const MEDIA_URL = config.media.endpoint;

  const image = MEDIA_URL+'/media/static/404.jpg';
  return (
    <Box
    sx={{
      margin:2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      minHeight: 'calc(100vh + 150px)'
    }}
    >
      <Container maxWidth="sm" sx={{ marginTop:5 }} >
        <Typography variant="h5" sx={{ mb:2 }}>
          Politique de Confidentialité de l'Application
        </Typography>
          <b>Bienvenue sur notre application ! Nous sommes déterminés à protéger votre confidentialité et à sécuriser vos informations personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos données lorsque vous utilisez notre application.</b>
        <Typography variant="h6" sx={{ m:2 }}>
          Collecte d'Informations
        </Typography>
          Nous collectons différentes informations lorsque vous utilisez notre application. Cela inclut :<br/>
          <u>Informations Personnelles :</u> Nous pouvons collecter des informations telles que votre nom, votre adresse e-mail, votre profession, votre localisation, etc., lorsque vous vous inscrivez ou utilisez certaines fonctionnalités de notre application.<br/>
          <u>Données d'Utilisation :</u> Nous recueillons automatiquement des informations sur la manière dont vous interagissez avec notre application, telles que les pages que vous consultez, les fonctionnalités que vous utilisez, et d'autres informations analytiques.
        <Typography variant="h6" sx={{ m:2 }}>
          Utilisation des Informations
        </Typography>
          Nous utilisons les informations collectées pour diverses raisons, notamment :<br/>
          <u>Fournir et Améliorer nos Services :</u> Nous utilisons vos informations pour fournir les services demandés, améliorer notre application, développer de nouvelles fonctionnalités et personnaliser votre expérience.<br/>
          <u>Communication :</u> Nous pouvons utiliser vos informations pour vous envoyer des notifications, des mises à jour sur nos services, des offres spéciales, et d'autres communications liées à notre application.<br/>
          <u>Sécurité :</u> Nous utilisons vos informations pour protéger notre application contre les activités frauduleuses, abusives ou illégales.
        <Typography variant="h6" sx={{ m:2 }}>
          Partage d'Informations
        </Typography>
          Nous ne vendons pas, ne louons pas et ne partageons pas vos informations personnelles avec des tiers, sauf dans le cas suivant :<br/>
          <u>Conformité Juridique :</u> Nous pouvons divulguer vos informations si nous sommes légalement tenus de le faire pour nous conformer à une obligation légale, protéger nos droits ou répondre à une demande gouvernementale.
        <Typography variant="h6" sx={{ m:2 }}>
          Protection des Données
        </Typography>
          Nous prenons des mesures pour protéger vos informations contre tout accès non autorisé, toute altération, toute divulgation ou toute destruction. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir une sécurité absolue de vos données.
        <Typography variant="h6" sx={{ m:2 }}>
         Modifications de la Politique de Confidentialité
        </Typography>
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Nous vous encourageons à consulter régulièrement cette page pour prendre connaissance des éventuelles mises à jour.
        <Typography variant="h6" sx={{ m:2 }}>
          Contactez-Nous
        </Typography>
          Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, veuillez nous contacter à meeteurs.com@gmail.com.<br/>
          En utilisant notre application, vous acceptez les termes de notre politique de confidentialité. Merci de votre confiance et de votre soutien continu !<br/><br/>


        <Typography variant="h5" sx={{ mb:2 }}>
          Application Privacy Statement
        </Typography>
          <b>Hello and welcome to our application! We want you to know that we take the protection of your personal data very seriously. This privacy statement explains how we collect, use, share, and protect your information when you use our application.</b>
        <Typography variant="h6" sx={{ m:2 }}>
          Information Collected
        </Typography>
          When you use our application, we may collect the following types of information:<br/>
          <u>Personal Information :</u> This may include your name, email address, phone number, and other information you choose to provide when you create an account or use our services.<br/>
          <u>Data Usage:</u> We automatically collect information about how you interact with our application, such as your IP address, the type of browser you use, the pages you visit, and similar data.<br/>
        <Typography variant="h6" sx={{ m:2 }}>
          Use of Information
        </Typography>
          We use the information collected for the following purposes:<br/>
          <u>Providing and Improving our Services</u> We use your information to provide you with the services you request, answer your questions, and process your transactions.We use the information to understand how our users interact with our application, improve the user experience, develop new features, and address technical issues.<br/>
          <u>Communication :</u> We may use your information to send you notifications, updates about our services, special offers, and other communications related to our application.<br/>
          <u>Security :</u> We use your information to protect our application against fraudulent, abusive, or illegal activities.
        <Typography variant="h6" sx={{ m:2 }}>
          Sharing of Information
        </Typography>
          We only share your personal information with third parties in the following case :<br/>
          <u>Legal Compliance:</u> We may disclose your information if we are legally required to do so to comply with a legal obligation, protect our rights, or respond to a government request.
        <Typography variant="h6" sx={{ m:2 }}>
          Data Protection
        </Typography>
        We take reasonable measures to protect your information against unauthorized access, misuse, loss, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security of your data.
        <Typography variant="h6" sx={{ m:2 }}>
          Changes to the Privacy Statement
        </Typography>
          We reserve the right to modify this privacy statement at any time. We encourage you to check this page regularly for updates.
        <Typography variant="h6" sx={{ m:2 }}>
        Contact Us
        </Typography>
          If you have any questions, concerns, or comments about our privacy policy, feel free to contact us at meeteurs.com@gmail.com or via our contact details provided in the application.<br/>
          By using our application, you agree to the terms of this privacy statement. Thank you for your trust and continued support!<br/><br/>

          Dernière mise à jour/Last Update : 11/03/2024
      </Container>
    </Box> 
  );
}

export default Privacy
