
import { Link, Typography } from '@mui/material';

const Copyright=(props) => {
  return (
    <Typography variant="body" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href=".">
        Meeteurs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const changeLastKeyValue = (obj, props, kobj=undefined) => {
  // Function used to iterate an object and change the value of the last key/value pair by editProps.value
  // Ex : {"field_name":''} will be changed with {"field_name":editProps.value}
  // Ex2 : {"profile":{"bio":''}} will be changed with {"profile":{"bio":editProps.value}}
  let temp={}
  if (typeof obj === 'object') {
    for (const [key, value] of Object.entries(obj)) {
      if (kobj !== undefined) {
        temp[kobj]=changeLastKeyValue(value, props, key)
      } else {
        temp=changeLastKeyValue(value, props, key)
      }
      return temp
    }
  } else {
    temp[kobj]=props
    return temp
  }
}

const formatMessageTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const currentdate = new Date()
  const localdate=date.toLocaleDateString("fr-FR");
  const currentlocaldate=currentdate.toLocaleDateString("fr-FR");
  const localtime=date.toLocaleTimeString("fr-FR");
  if (localdate===currentlocaldate) {
      return localtime;
  } else {
      return localdate + " " + localtime;
  }
}

export {
  changeLastKeyValue,
  formatMessageTimestamp,
  Copyright
}
