export function getScreenSize() {
    const { innerWidth } = window;
    let breakpoint = 'xs';
    if (innerWidth >= 1536) {
      breakpoint = 'xl';
    } else if (innerWidth >= 1200) {
      breakpoint = 'lg';
    } else if (innerWidth >= 900) {
      breakpoint = 'md';
    } else if (innerWidth >= 600) {
      breakpoint = 'sm';
    }
  
    return breakpoint;
  }