import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { debounce } from '@mui/material/utils';
import AppService from "../services/app.service";
import { Chip } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';


export default function Tags({tags, setTags, sx, label}) {
  //const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  if (label===undefined) { label="Tag (press enter to validate) *" }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        if (request.input.length>2) {
          AppService.newsearchTag(request.input, callback);
        }       
      }, 400),
    [],
  );

  const addChip = (value) => {
    let v=value.trim()
    if ((tags.filter(e => e === v).length === 0)) {
      setTags(tags => [...tags, v])
    }
  }


  React.useEffect(() => {
    let active = true;

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Box sx={sx}>
      <Autocomplete
        key={refresh}
        id="tag-picker"
        className='tags'
        filterOptions={(x) => x} // Override filter option (no filtering from autocomplete component)
        options={options}
        autoComplete
        filterSelectedOptions
        includeInputInList
        fullWidth
        //value={value}
        noOptionsText="No tags"
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => {
          addChip(newValue.name)
          setInputValue('')
          setRefresh(!refresh)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} 
            inputProps={{ ...params.inputProps, maxLength: 20 }}
            label={label}
            fullWidth
            onKeyDown={(e) => {
                    if (e.key === 'Enter') {  // Bypass default Enter 
                      //console.log("enter !!!")
                      e.stopPropagation();
                      addChip(inputValue)
                      setInputValue('')
                      setRefresh(!refresh)
                    }
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          const matches =
            option || [];

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <TagIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Box
                    key={matches}
                    component="span"
                    sx={{ fontWeight: matches.highlight ? 'bold' : 'regular' }}
                  >
                    {matches.name}
                  </Box>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />

      <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
        {tags.map((tag, index) => (
          <Box sx={{ml:1, mb:1}} key={index}>
            <Chip
              label={tag}
              onDelete={() => {
                  setTags(tags.filter(e => e !== tag))
                }
              }
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}