import { updateExp } from "./auth.service";

const getLocalRefreshToken = () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return tokens?.refresh;
};

const getLocalAccessToken = () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return tokens?.access;
};

const updateLocalAccessToken = (token) => {
  let tokens = JSON.parse(localStorage.getItem("tokens"));
  updateExp(token)
  tokens.access = token;
  localStorage.setItem("tokens", JSON.stringify(tokens));
};

const deleteLocalAccessToken = () => {
  localStorage.removeItem("tokens");
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  deleteLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
};

export default TokenService;
