import React, { useState, useContext } from "react";
import { Button, TextField, Box, Typography, Container } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Location from '../components/Location';
import Tags from '../components/Tags';
import AppService from "../services/app.service";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { snackbarContext } from '../contexts/SnackbarContext';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/reducers/userSlice.js';

export default function Meet(props) {
  const reduxuser = useSelector(selectUser);
  const { t } = useTranslation();
  const { setSnack } = useContext(snackbarContext);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState("");
  const [tags, setTags] = useState(["OpenToTalk"])
  const [value, setValue] = useState(Date.now);
  const [meetname, setMeetname] = useState("");
  const [advance, setAdvance] = useState(false);
  const [meeteurs, setMeeteurs] = useState(6);
  const [currentrealm, setCurrentrealm] = useState(1);
  const [realms, setRealms] = useState([]);
  const [dateRO, setDateRO] = useState(false);
  const readonly = React.useRef(false)
  let default_tags=reduxuser.profile.tags
  let default_location=reduxuser.profile.location
  let { meetId } = useParams();
  let navigate = useNavigate();

  React.useEffect(()=> {
    //console.log("refresh")

  }, [meetId])

  if (loading!==true) {
    setLoading(true)
    AppService.getRealms((results) => {
      if (results.status!==200) {
        // TODO process errors
        setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
      } else {
        setRealms(results.data)
      }
    })
  }

  if ((meetId !== undefined) && (loading!==true)) {
    setLoading(true)
    setEditing(true)
    readonly.current=true
    // Load previous meet data
    AppService.getMeet(meetId, (results) => {
      if (results.status!==200) {
        // TODO process errors
        if (results.response.status===404) {
          // redirect to home page
          navigate("/")
          setSnack({message:t('common.notfound'), severity:"error", open:true})
        } else {
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        }
      } else {
        if (results.data.iadmin) { }//readonly.current=true }
        setMeetname(results.data.name)
        setValue(Date.parse(results.data.date))
        // Check if we are less than 2 hours before the meet begins
        if (Date.parse(results.data.date)-Date.now() < 7200000) {
          setDateRO(true)
        }
        console.log(Date.parse(results.data.date)-Date.now())
        let nlocation={id:"new", "label":results.data.location}
        setLocation(nlocation)   
        let meettags=results.data.tags
        let ntags=[]
        meettags.map((meettag, index) => (
          ntags.push(meettag.name)
        ))
        setTags(ntags)
        setMeeteurs(results.data.max_members)
        setCurrentrealm(results.data.realm.id)
      }

    });
  }

  const copyTags = () => {
    const newtags=[...tags] // Need this to create a new array object
    default_tags.forEach((newtag, index) => {
      if (newtags.indexOf(newtag.name) === -1) {
        newtags.push(newtag.name)
      }
    })
    //console.log(newtags)
    setTags(newtags)
  }


  const mymeets = () => {
    let path = `/mymeets`;
    navigate(path);
  }

  const displayRealm=(realm) => {
    return(<MenuItem value={realm.id}>{realm.name}</MenuItem>)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
   
    //const data = new FormData(event.currentTarget);
    let meetdate=new Date(value).toISOString()
    let meetlocation=location.label
    let meettags=tags
    let maxmembers=meeteurs
    let newmeet={
      "name":meetname,
      "location":meetlocation,
      "date":meetdate,
      "update_tags":meettags,
      "max_members":maxmembers,
      "realm":currentrealm
    }
    if (meetname==="" || meetdate==="" || meettags.length===0 || meetlocation===undefined) {
      //console.log(meetname + " " + meetdate +  " " + meettags + " " + meetlocation)
      setSnack({message:t('meet.all_fields_required'), severity:"error", open:true})
    } else {
      if (editing===false) {
        AppService.createMeet(newmeet, (results) => {
          if (results.status!==201) {
            // TODO process errors
            setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          } else {
            mymeets();
          }

        });
      } else {
        AppService.patchMeet(meetId, newmeet, (results) => {
          if (results.status!==200) {
            // TODO process errors
            setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          } else {
            mymeets();
          }

        });
      }


    }
  };

  const displayAdvance = () => {
    if (advance===false) {
      return(  
      <>    
        <Box sx={{ gridColumn: "span 20", display:"flex", alignItems:"center"}} style={{ cursor: 'pointer' }} onClick={()=>{setAdvance(true)}}>{t('common.options')}<ArrowDropDownIcon/></Box>
      </>
      )
    } else {
      return(  
        <>    
          <Box sx={{ gridColumn: "span 20", display:"flex", alignItems:"center"}} style={{ cursor: 'pointer' }} onClick={()=>{setAdvance(false)}}>{t('common.options')}<ArrowDropUpIcon/></Box>
            <FormControl sx={{ mt:"1", gridColumn: "span 20" }} >
              <InputLabel id="maxmeeteurs">{t('meet.max_meeteurs')}</InputLabel>
              <Select
                labelId="maxmeeteurs-label"
                id="maxmeeteurs-select"
                className="maxmeeteurs"
                value={meeteurs}
                readOnly={readonly.current}
                onChange={(newValue) => {
                  setMeeteurs(newValue.target.value);
                }}
              >
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mt:"1", gridColumn: "span 20" }} >
              <InputLabel id="realms">{t('common.realms')}</InputLabel>
              <Select
                labelId="realms-label"
                id="realms-select"
                className="meetgroup"
                value={currentrealm}
                readOnly={readonly.current}
                onChange={(newValue) => {
                  setCurrentrealm(newValue.target.value);
                }}
              >
                {realms.map((realm, index) => (
                  displayRealm(realm)
                ))}
              </Select>
            </FormControl>
        </>
        )
    }
  }

  const displayCopyLocation=()=> {
    if (editing!==true) {
      return(
        <Button
          variant="contained"
          className="copylocation"
          onClick={()=>{setLocation({label:default_location})}}
        >
          Copy Location
        </Button>)
    }
  }

  let title=t('meet.new');
  if (editing===true) {
    title=t('meet.edit');
  }

  return (
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'calc(100vh + 150px)'
          }}
        >
          <Typography component="h1" variant="h5">
          <ModeOfTravelIcon />{title}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ m: 1, alignItems:'center', display: 'grid', gap: 1, gridTemplateColumns: 'repeat(20, 1fr)' }}>
            <TextField
              sx={{ mt:1, gridColumn: "span 20" }}
              required
              value={meetname}
              className="objectmeet"
              onChange={(newValue) => {
                setMeetname(newValue.target.value);
              }}
              fullWidth
              id="name"
              label={t('meet.purpose')}
              name="name"
              variant="outlined"
              inputProps={{ maxLength: 200 }}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                ampm={false}
                disabled={dateRO}
                className="datetimemeet"
                label={t('meet.when')}
                inputFormat="DD/MM/yyyy HH:mm"
                renderInput={(params) => <TextField id="datetime" variant="outlined" required fullWidth {...params} sx={{ gridColumn: "span 20" }}  />}
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
            />
            </LocalizationProvider>
            <Location readonly={readonly.current} label={t('meet.where')} value={location} setValue={setLocation} sx={{ mt:"1", gridColumn: "span 19" }} />
            <Tags label={t('meet.tags')} tags={tags} setTags={setTags} sx={{ gridColumn: "span 20" }}/>
            {displayAdvance()}
            <Box display="flex" justifyContent="space-around" sx={{ gridColumn: "span 20"}}>
              <Button
                type="submit"
                variant="contained"
              >
                {t('meet.go')}
              </Button>
              <Button
                variant="contained"
                className="copytag"
                onClick={()=>{copyTags()}}
              >
                Copy Tags
              </Button>
              {displayCopyLocation()}
            </Box>
          </Box>
        </Box>
      </Container>
  );
}
