import React from 'react'
import { Box } from '@mui/material'
import DashboardCreateMeet from '../components/DashboardCreateMeet';
import DashboardRealms from '../components/DashboardRealms';

export default function Dashboard() {
  const [globalLoad, setGlobalLoad] = React.useState(false);
  return (
    <Box
      sx={{
        margin:2,
        display: 'grid',
        gridTemplateColumns: { xs: 'none' , md: 'repeat(2, 1fr)' } ,
        gridTemplateRows: { xs: 'repeat(2, 1fr)' , md: 'none' } ,
        minHeight: 'calc(100vh + 150px)'
      }}
    >
      <DashboardRealms setGlobalLoad={setGlobalLoad}/>
      <DashboardCreateMeet globalLoad={globalLoad} setGlobalLoad={setGlobalLoad}/>
    </Box>
  );
}

