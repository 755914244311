import React, { createContext, useState } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

export const snackbarContext = createContext({});


const SnackbarProvider = ({ children }) => {
  const [snack, setSnack] = useState({ message: '', severity: 'error', open: false });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({open: false});
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <snackbarContext.Provider value={{ snack, setSnack }}>
      { children }
        <Snackbar open={snack.open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={snack.severity} sx={{ width: '100%' }}>
            {snack.message}
          </Alert>
        </Snackbar>
    </snackbarContext.Provider>
  );
};

export default SnackbarProvider;
