import L from 'leaflet';


const icon = L.icon({
    iconUrl: require('../img/icons/marker-icon-blue.png'),
    iconRetinaUrl: require('../img/icons/marker-icon-blue.png'),
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(25, 40)
});

var LeafIcon = L.DivIcon.extend({
  options: {
      iconSize: [30, 42],
      iconAnchor: [15, 42],
      className: 'custom-div-icon'
  }
});

const redIcon = L.icon({
    iconUrl: require('../img/icons/marker-icon-red.png'),
    iconRetinaUrl: require('../img/icons/marker-icon-red.png'),
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(25, 40)
});

const greenIcon = L.icon({
    iconUrl: require('../img/icons/marker-icon-green.png'),
    iconRetinaUrl: require('../img/icons/marker-icon-green.png'),
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(25, 40)
});

const goldIcon = L.icon({
    iconUrl: require('../img/icons/marker-icon-gold.png'),
    iconRetinaUrl: require('../img/icons/marker-icon-gold.png'),
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(25, 40)
});

export { icon , redIcon, greenIcon, goldIcon, LeafIcon } 