import api from "./api";
import jwt from 'jwt-decode';
const config = require('../config/default');

const API_URL = config.api.endpoint;

const registerUser = (username, email, password, first_name, last_name) => {
  return api.post(API_URL + "/register/", {
    username,
    email,
    password,
    first_name,
    last_name
  }).then((response) => {
    return response.data; // String expected
  },(error) => {
    return JSON.stringify(error.response.data); // Object expected
  });

};

const getUserDetails = () => {
  return api
    .get(API_URL + "/me/")
    .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));

        }
        return response.data;
    });
};


function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}


const login = (username, password) => {
  return api
    .post(API_URL + "/token/", {
      username,
      password,
    })
    .then((response) => {
      if (response.data) {
        updateExp(response.data)
        let tokens=JSON.stringify(response.data)
        localStorage.setItem("tokens", tokens);
        
      }
      return response.data;
    });
};

const googleLogin = (idToken) => {
  return api
    .post(API_URL + "/googletoken/", {
      idToken,
    })
    .then((response) => {
      if (response.data) {
        updateExp(response.data)
        let tokens=JSON.stringify(response.data)
        localStorage.setItem("tokens", tokens);
        
      }
      return response.data;
    });
};

const updateExp = (tokens) => {
  if (typeof tokens === "string") {
    // only access token refresh
    let decodeToken=jwt(tokens)
    localStorage.setItem("access", decodeToken["exp"])
  } else {
    // object containing refresh & access token retrieve on login
    let refresh=jwt(tokens["refresh"])
    let access=jwt(tokens["access"])
    localStorage.setItem("refresh", refresh["exp"])
    localStorage.setItem("access", access["exp"])
  }
}

const logout = (setAuthData) => {
  setAuthData()
  localStorage.removeItem("tokens");
  localStorage.removeItem("refresh");
  localStorage.removeItem("access");
  localStorage.removeItem("user");
  localStorage.removeItem("persist:root");
  return true;
};

export {
  sleep,
  registerUser,
  updateExp,
  getUserDetails,
  login,
  logout,
  googleLogin,
}
