import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MapMenu = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const meetexplore = () =>{
    let path = `/meetexplore`;
    navigate(path);
  }
  const mymeets = () =>{
    let path = `/mymeets`;
    navigate(path);
  }
  const createmeet = () =>{
    let path = `/meet`;
    navigate(path);
  }

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);



  return (
      <>
        <Button
          ref={anchorRef}
          id="composition-button"
          className='menumeets'
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          {t('toolbar.meets')}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={(event) => {
                      handleClose(event)
                      meetexplore()
                      }}>{t('toolbar.meetexplore')}</MenuItem>
                    <MenuItem onClick={(event) => {
                      handleClose(event)
                      mymeets()
                      }}>{t('toolbar.mymeets')}</MenuItem>
                    <MenuItem onClick={(event) => {
                      handleClose(event)
                      createmeet()
                      }}>{t('toolbar.createmeet')}</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>

  );




  
}

export {
    MapMenu
}
