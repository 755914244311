import React, {useRef, useState, useEffect} from 'react'
import './App.css'
import TopNavBar from './components/TopNavBar'
import { Toolbar } from '@mui/material'
import Routing from './routes/Routing'
import { Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import SnackbarProvider from './contexts/SnackbarContext';
import { initializeApp } from "firebase/app";
import WebNotification from './components/WebNotification'
import MobileNotification from './components/MobileNotification'
import { appsettingsContext } from './contexts/AppsettingsContext';
import DialogProvider from './contexts/DialogContext'
import { SafeArea } from 'capacitor-plugin-safe-area';
import Joyride, { STATUS } from 'react-joyride';
import { getScreenSize } from './modules/helpers';
import { useTranslation } from "react-i18next";
const config = require('./config/default');



async function IOSNotch(bns, setBns) {
  SafeArea.getSafeAreaInsets().then((data) => {
    const { insets } = data;
    for (const [key, value] of Object.entries(insets)) {
      //console.log("NOTCH:", key, " value ", value)
      if (key==="bottom") {
        if (bns !== value) {
          setBns(value)
          document.documentElement.style.setProperty(`--safe-area-bottom`,`${value}px`);
        }
      }
      document.documentElement.style.setProperty(
        `--safe-area-${key}`,
        `${value}px`,
      );
    }
  });

  /*
  SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
    console.log("SEB:Start")
    console.log(statusBarHeight, 'statusbarHeight');
    console.log("SEB:Stop")
  });*/
  
  await SafeArea.addListener('safeAreaChanged', data => {
    const { insets } = data;
    for (const [key, value] of Object.entries(insets)) {
      //console.log("NOTCH:", key, " value ", value)
      if (key==="bottom") {
        if (bns !== value) {
          setBns(value)
        }
      }
      document.documentElement.style.setProperty(
        `--safe-area-${key}`,
        `${value}px`,
      );
    }
  });
  
  //setAppsettings(newsettings)
  //await SafeArea.removeAllListeners();
}


async function initializeFirebase() {
  if (Capacitor.isNativePlatform()) {
    MobileNotification()
    return;
  }
  let firebaseConfig={
    apiKey: "AIzaSyAedRJYVNzF3KK9CwrGaMPye12oUtHx1RY",
    authDomain: "meeters-98bfc.firebaseapp.com",
    projectId: "meeters-98bfc",
    storageBucket: "meeters-98bfc.appspot.com",
    messagingSenderId: "142678078891",
    appId: "1:142678078891:web:54e5ba56af10b4090f0eee",
    measurementId: "G-QRRJ2T6VQC",
  }

  //console.log(firebaseConfig)
  initializeApp(firebaseConfig);
}

/*
function notchTest(size) {
  document.documentElement.style.setProperty('--safe-area-top', size);
}*/

function App() {
  const [ bns, setBns ]= React.useState(0);
  const { t } = useTranslation();
  //document.documentElement.style.setProperty(`--safe-area-bottom`,`${bns}px`);
  const { appsettings, setAppsettings } = React.useContext(appsettingsContext);

  const [breakpoint, setBreakpoint] = useState(getScreenSize());
  appsettings['breakpoint']=breakpoint
  const debounceTimeout = useRef(0);

  const handleResize = useRef(() => {
    clearTimeout(debounceTimeout.current);

    debounceTimeout.current = window.setTimeout(() => {
      setBreakpoint(getScreenSize());
      appsettings['breakpoint']=breakpoint
    }, 250);
  });

  useEffect(() => {
    const { current } = handleResize;

    window.addEventListener('resize', current);

    return () => {
      window.removeEventListener('resize', current);
    };
  });


  initializeFirebase()
  if (Capacitor.getPlatform() === 'ios') {
    IOSNotch(bns, setBns);
  }
  

  const MEDIA_URL = config.media.endpoint;
  

  const darkBG='url('+MEDIA_URL+'/media/static/background-dark.jpg)';
  const lightBG='url('+MEDIA_URL+'/media/static/background-light.jpg)';
  const getDesignTokens = (mode, bns) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode

          }
        : {
            // palette values for dark mode

          }),
      },
      components: {
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 64,
                    minHeight: 64,
                    marginBottom: bns
                }
            }
        },
        MuiCssBaseline: {
          styleOverrides: {
            ...(mode === 'light'
            ? {
                body: {
                  backgroundImage: lightBG,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "fixed"
                }
              }
              : {
                body: {
                  backgroundImage: darkBG,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "fixed"
                }
              }),
          }
        }
      }
  });
  
  const theme = React.useMemo(
    () =>
      createTheme(getDesignTokens(appsettings['Mode'], bns)),
    [appsettings, bns],
  );

  // Manage to use back button for app navigation (by default exit app)
  CapacitorApp.addListener('backButton', ({canGoBack}) => {
    if(!canGoBack){
      // Do nothing
      //CapacitorApp.exitApp();
    } else {
      window.history.back();
    }
  });

  
  const [run, setRun]=React.useState(false)
  const [steps, setSteps]=React.useState([])

  
  appsettings["run"]=run
  appsettings["setRun"]=setRun
  appsettings["setSteps"]=setSteps
  appsettings["steps"]=steps
  setAppsettings(appsettings)

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // You need to set our running state to false, so we can restart if we click start again.
      setRun(false);
    }

  };

  return(
    
    <ThemeProvider theme={theme}>
      <Joyride
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        continuous
        disableOverlay
        showSkipButton
        locale={{ back: t('tour.back'), close: t('tour.close'), last: t('tour.last'), next: t('tour.next'), open: t('tour.open'), skip: t('tour.skip') }}
        styles={{
            options: {
              zIndex: 10000,
            },
          }}/>
      <SnackbarProvider>
      <DialogProvider>
      <CssBaseline />
      <Box
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 84px - var(--safe-area-top) - var(--safe-area-bottom))" /* 100vh - toolbar size in px + 20 (20px for 2x margin of 10px) */
        margin="10px"
      >

        <Routing />
        <Toolbar /> {/* Take care of TopNavBar size */}
        <TopNavBar />
        <WebNotification />
      </Box>
      </DialogProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )

}



export default App;
