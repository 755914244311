import React, {useState, useEffect, useContext, useRef } from 'react'
import { Capacitor } from '@capacitor/core';
import AppService from "../services/app.service";
import  { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { authContext } from '../contexts/AuthContext';
import { dialogContext } from '../contexts/DialogContext';
import { snackbarContext } from '../contexts/SnackbarContext';
import { useTranslation } from "react-i18next";
import toast, { Toaster } from 'react-hot-toast';

const WebNotification = () => {
    const { t } = useTranslation();
    const { auth } = useContext(authContext)
    const allowed = useRef(null)
    const [token, setToken] = useState("")
    const { setDialog } = useContext(dialogContext);
    const [openDialog, setOpenDialog] = useState(false)
    const { setSnack } = React.useContext(snackbarContext);


    
    const requestNotif = async() => {

      const iWantNotif=()=> {
        Notification.requestPermission().then(function (status) {
          if (status === 'denied') {
            allowed.current=false
          } else if (status === 'granted') {
            allowed.current=true
            retrieveToken();
          }
      });
      }

      if (Capacitor.getPlatform()==="web" && (auth!==null && auth?.tokens !== null && auth?.tokens !== undefined)) {
        if (openDialog===false) {
          setOpenDialog(true)
          if ("Notification" in window && allowed.current===null) {
            if (Notification.permission === "granted") {
              // Check whether notification permissions have already been granted;
              allowed.current=true
            } else if (Notification.permission !== "denied") {
              // We need to ask the user for permission
              setDialog({open:true, title:t('notification.title'), message:[t('notification.message')], okButton:t('common.ok'), callback:iWantNotif, cancelCallback:iWantNotif})
            } else if (Notification.permission === "denied") {
              // Denied by user
              allowed.current=false
            }
            
          } else {
            //console.log('Firebase not supported this browser');
            setSnack({message:t('notification.unsupportedbrowser'), severity:"warning", open:true})
            allowed.current=false
          }
        }
      } 
      
    }

    useEffect(() => {
      requestNotif();
    }) 



    const retrieveToken = async () => {
      const options = {
        vapidKey: "BGFgus-wy9sh6qsUnTjcsvNcsprEoVggWTL0cwGbCBepVasu04v8W9U1CRKixpiS-rC7Tn1JEGHR_6ASfDT-gr0",
      };
      options.serviceWorkerRegistration = await navigator.serviceWorker.register("firebase-messaging-sw.js").catch(error => { /* console.log("ServiceWorker Warning: ", error) <- error in FF https://stackoverflow.com/questions/58470055/unhandled-rejection-securityerror-the-operation-is-insecure-on-a-fresh-creat */ } );
      let t = await FirebaseMessaging.getToken(options);
      let updateToken={"registration_id":t['token'], "type":"web"}
      AppService.updateFCMDevice(updateToken, (results) => {
        if (results.status===200 || results.status===201) {
          setToken(t)
        } else {
          // Snack for error
          setSnack({message:"notifications may not work", severity:"warning", open:true})
        }
      }); 
    }


    const [notification, setNotification] = useState({title: '', body: ''});
    const notify = () =>  toast(<ToastDisplay/>);
    function ToastDisplay() {
      return (
        <div>
          <p><b>{notification?.title}</b></p>
          <p>{notification?.body}</p>
        </div>
      );
    };

    useEffect(() => {
      if (Capacitor.getPlatform() === 'web') {
        if (notification?.title ){
          notify()
        }
      }
    }, [notification])



    useEffect(() => {


      if (allowed.current === true && token ===""){ 
        retrieveToken()
      }
      else if (allowed.current === true && token !=="") {
        /*
        FirebaseMessaging.addListener("notificationReceived", (event) => {
          console.log("notificationReceived: ", { event });
        });
        FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
          console.log("notificationActionPerformed: ", { event });
        });*/
        if (Capacitor.getPlatform() === "web") {
          navigator.serviceWorker.addEventListener("message", event => {
            //console.log("serviceWorker message: ", { event });
            setNotification({title: event?.data?.notification?.title, body: event?.data?.notification?.body});
            /*const notification = new Notification(event.data.notification.title, {
              body: event.data.notification.body,
            });*/

            /*
            notification.onclick = (event) => {
              console.log("notification clicked: ", { event });
            };*/
          });
        }


      }
    },[allowed.current, token])


    return (
      <Toaster/>
    )

    
  }

  


export default WebNotification
