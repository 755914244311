import React from 'react';
import LightModeIcon from '@mui/icons-material/LightMode';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import { appsettingsContext } from '../contexts/AppsettingsContext';
import { authContext } from '../contexts/AuthContext';
import AppService from '../services/app.service';

const DarkLight = () => {
  const { appsettings, setAppsettings } = React.useContext(appsettingsContext);
  const { auth } = React.useContext(authContext);

  const changeMode = (to) => {
    let data={
      "theme":"L"
    }
    if (to==="dark") {
      data['theme']="B"
    }
    let newsettings={...appsettings}
    newsettings['Mode']=to
    setAppsettings(newsettings)
    //console.log("ChangeTO", newsettings)
    if (auth.tokens!==null && auth.tokens!==undefined) {
      AppService.patchMe(data, (results) => {});
    }
  }

  let data={
    "theme":"L"
  }

  if (appsettings['Mode']==="light") {
    data['theme']="B"

    return (
      <ModeNightIcon
        className='menutheme'
        sx={{mr:'5px'}}
        onClick={()=> {
          changeMode("dark")
        }}
      >
      </ModeNightIcon>
    )
  } else {
    return (
      <LightModeIcon
        className='menutheme'
        sx={{mr:'5px'}}
        onClick={()=> {
          changeMode("light")
        }}
      >
      </LightModeIcon>
    )
  }


}


export {
  DarkLight
}
  