import axios from "axios";
import TokenService from "./token.service";
const config = require('../config/default');


const instance = axios.create({
  baseURL: config.api.endpoint,
  headers: {
    "Content-Type": "application/json",
  },
});

/*
let refreshing=false

async function refreshAccessToken() {
  try {
    const refreshToken = TokenService.getLocalRefreshToken();
    const response = await instance.post("/token/refresh/", {
      refresh: refreshToken
    });
    let access = response.data.access;
    TokenService.updateLocalAccessToken(access);
    refreshing=false
  } catch (err) {
    console.error(err);
    throw err;
  }
}
*/

instance.interceptors.request.use(
  (config) => {
    
    // Check if access token is expired
    /*
    let access=localStorage.getItem("access")
    if (access !== null) {
      if (access >= Date.now()/1000 && refreshing===false) {
        refreshing=true
        refreshAccessToken()
        // refresh access token
        const refreshToken = TokenService.getLocalRefreshToken();
        return new Promise(function (resolve, reject) {
          instance.post("/token/refresh/", { refresh: refreshToken })
            .then(({data}) => {
                const { access } = data;
                TokenService.updateLocalAccessToken(access);
            })
            .catch((err) => {
                reject(err);
            })
          })
      }
   }*/

    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  const originalRequest=error.config
  if (error.response.status === 401) {
      if (isRefreshing) {
        window.location.href = '/logout';
      }
  } else {
    // For every other error code
    return Promise.reject(error);
  }

  isRefreshing = true;
  
  const refreshToken = TokenService.getLocalRefreshToken();
  return new Promise(function (resolve, reject) {
     instance.post("/token/refresh/", { refresh: refreshToken })
      .then(({data}) => {
          const { access } = data;
          TokenService.updateLocalAccessToken(access);
          instance.defaults.headers.common['Authorization'] = 'Bearer ' + access;
          originalRequest.headers['Authorization'] = 'Bearer ' + access;
          resolve(instance(originalRequest));
      })
      .catch((err) => {
          reject(err);
      })
      .then(() => {
        isRefreshing = false;
      })
    })
});

/*
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        console.log(JSON.stringify(err.response.status));
        try {
          const rs = await instance.post("/token/refresh/", {
            refresh: TokenService.getLocalRefreshToken(),
          });

          const { access } = rs.data;
          TokenService.updateLocalAccessToken(access);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);*/

export default instance;
