import React, { useContext, useEffect } from "react";
import { Capacitor } from '@capacitor/core';
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, Button, TextField, Link, Grid, Box, Container, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { authContext } from '../contexts/AuthContext';
import { appsettingsContext } from "../contexts/AppsettingsContext";
import { snackbarContext } from '../contexts/SnackbarContext';
import { googleLogin, login, getUserDetails } from "../services/auth.service";
import { userLogin } from '../redux/reducers/userSlice.js'
import { useDispatch } from 'react-redux';
import { Copyright } from "../components/helpers";
import { useTranslation } from "react-i18next";
import GoogleButton from 'react-google-button'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

export default function SignIn(props) {
  const { state } = useLocation();
  const { t } = useTranslation();
  const { setAuthData } = useContext(authContext);
  const { setAppsettings } = useContext(appsettingsContext);

  const { setSnack } = useContext(snackbarContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Capacitor.getPlatform()==="web") {
      /* global google */
      google.accounts.id.initialize({
        client_id:"142678078891-d85pbnrungrpj38mloauhma2ucig35p6.apps.googleusercontent.com",
        callback: GisButton
      });
    
      google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        { theme: "outline", size: "large" }
      )
    }
  });

  async function GisButton(response) {
    googleLogin(response.credential).then(
      (result) => {
        setAuthData(result);
        getUserDetails().then(
          (result) => {
                    if (result.profile.theme==="L") {
                      setAppsettings({Mode:"light"})
                    } else {
                      setAppsettings({Mode:"dark"})
                    }
                    dispatch(userLogin(result));
                    props.navigate(-1); // Redirect to previous page
          },
          (error) => { setSnack({message:t('login.err_retrieve_user'), severity:"error", open:true}) }
        );
        //dispatch(fetchMe({reduxitem}));
      },
      (error) => {
        var errcode = error?.response?.status;       
        if (errcode===400) {
          setSnack({message:t('login.failed'), severity:"error", open:true})
        } else if (errcode>=500) {
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        } else {
          setSnack({message:t('common.network_issue'), severity:"error", open:true})
        }

      }
    );
  }

  let navigate = useNavigate();
  const registerLink = () =>{
    let path = `/register`;
    navigate(path, { state: state });
  }

  const displayButton = () => {
    if (Capacitor.getPlatform()==="web") {
      return(
        <Box display="flex" maxWidth='100vl' width='100vl' alignContent="center" justifyContent="center" marginBottom="10px">
          <div id="signInDiv"></div>
        </Box>
        )
    } else {
      return(
        <GoogleButton
          label={t('login.signin_google')}
          style={{width:'100vl', maxWidth:'100vl'}}
          onClick={() => { googleButton() }}
        />)
    }
  }


  async function googleButton() {
    const response = await GoogleAuth.signIn();
    // Get idToken (JWT data)
    if (response.authentication!==undefined && response.authentication?.idToken) {
      //console.log(response.authentication.idToken)
      googleLogin(response.authentication.idToken).then(
        (result) => {
          setAuthData(result);
          getUserDetails().then(
            (result) => {
                      dispatch(userLogin(result));
                      props.navigate(-1); // Redirect to previous page
            },
            (error) => { setSnack({message:t('login.err_retrieve_user'), severity:"error", open:true}) }
          );
          //dispatch(fetchMe({reduxitem}));
        },
        (error) => {
          var errcode = error?.response?.status;       
          if (errcode===400) {
            setSnack({message:t('login.failed'), severity:"error", open:true})
          } else if (errcode>=500) {
            setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          } else {
            setSnack({message:t('common.network_issue'), severity:"error", open:true})
          }
  
        }
      );
    }
    

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let username=data.get('email')
    let password=data.get('password')
    login(username, password).then(
      (result) => {
        setAuthData(result);
        getUserDetails().then(
          (result) => {
                    dispatch(userLogin(result));
                    props.navigate(-1); // Redirect to previous page
          },
          (error) => { /*TODO, exit app ?? */ }
        );
        //dispatch(fetchMe({reduxitem}));
      },
      (error) => {
        var errcode = error?.response?.status;       
        if (errcode===400) {
          setSnack({message:t('login.failed'), severity:"error", open:true})
        } else if (errcode>=500) {
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
        } else {
          setSnack({message:t('common.network_issue'), severity:"error", open:true})
        }

      }
    );

  };

  return (
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('login.signin')}
          </Typography>
          <Typography component="h1" variant="h5" color="red">
            Alpha Release
          </Typography>
          <Typography component="h1" variant="h5" color="orange" align="center">
            {t('login.onlygoogle')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {displayButton()}
            <TextField
              margin="normal"
              required
              disabled
              fullWidth
              id="email"
              label={t('common.email')}
              name="email"
              autoComplete="email"
              autoFocus
              variant="outlined"
            />
            <TextField
              margin="normal"
              required
              disabled
              fullWidth
              name="password"
              label={t('common.password')}
              type="password"
              id="password"
              autoComplete="current-password"
              variant="outlined"s
            />
            <Button
              type="submit"
              disabled
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="primary"
            >
              {t('login.signin')}
            </Button>
            
            <Grid container>
              <Grid item xs>
                <Link style={{ cursor: 'pointer' }} onClick={()=>{/*registerLink()*/}} variant="body">
                {t('login.forgot_password')}
                </Link>
              </Grid>
              <Grid item>
                <Link style={{ cursor: 'pointer' }} onClick={()=>{/*registerLink()*/}} variant="body">
                {t('login.create_account')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}
