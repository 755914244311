import React from 'react'
import { useTranslation } from "react-i18next";

function UpdatePage() {
  const { t } = useTranslation();
  return (
    <>
        <h2>{t('updatePage.title')}</h2>
        <p style={{fontFamily:'Courier New'}}>
        {t('updatePage.message')}
        </p>

    </>
  );
}

export default UpdatePage
