import React, { useEffect, useState, useContext } from 'react';
import AppService from "../services/app.service";
import { useNavigate, useParams } from "react-router-dom";
import { MeetCard } from '../components/MeetCard';
import { Typography, Box, Container, Divider } from '@mui/material';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import { useTranslation } from 'react-i18next';
import { snackbarContext } from '../contexts/SnackbarContext';
import { dialogContext } from '../contexts/DialogContext';

function Meetinfos() {
  let { meetId } = useParams();
  const { setSnack } = useContext(snackbarContext);
  const { setDialog } = useContext(dialogContext);
  const { t } = useTranslation();
  const [content, setContent] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (reload===false && meetId!==undefined) {
      AppService.getMeet(meetId, (results) => {
        if (results.status!==200) {
          setSnack({message:t('common.service_unavailable'), severity:"error", open:true})
          setReload(!reload)
        } else {
          setContent(results.data);
          setReload(!reload)
        }
      })
    }

  }, [reload]);


  const nav=useNavigate();
  if (content === "") {
    return(<></>);
  } else {
    return (
      <Container maxWidth="xl">
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ModeOfTravelIcon />
        <Typography component="h1" variant="h5">
          {t('meet.detail')}
        </Typography>
        <Divider />
        <Box sx={{ pt:2, display: 'flex' }}> 
          {MeetCard(content, 0, nav, setReload, reload, setSnack, t, setDialog)}
        </Box>
      </Box>
      </Container>
    );
  }
};
export default Meetinfos
