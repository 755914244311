import React, {useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../services/auth.service";
import { Container, Box, Avatar, Typography, TextField, Button, Alert } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Copyright } from "../components/helpers";


const Register = (props) => {
  const { state } = useLocation();
  
  const [email, setEmail]=useState("")
  const [pwd, setPwd]=useState("")
  const [confirmpwd, setConfirmpwd]=useState("")
  const [username, setUsername]=useState("")
  const [firstname, setFirstname]=useState("")
  const [lastname, setLastname]=useState("")
  const [message, setMessage]=useState("")

  const [emailE, setEmailE]=useState({error:false, helper:""})
  const [pwdE, setPwdE]=useState({error:false, helper:""})
  const [confirmpwdE, setConfirmpwdE]=useState({error:false, helper:""})
  const [usernameE, setUsernameE]=useState({error:false, helper:""})
  const [firstnameE, setFirstnameE]=useState({error:false, helper:""})
  const [lastnameE, setLastnameE]=useState({error:false, helper:""})

  let navigate = useNavigate();
  const thanks = () => {
    let path = `/thanks`;
    navigate(path, { state: state });
  }

  //console.log(navigate)

  const onSubmit = (event) => {
    event.preventDefault()
    if (firstname==="" || lastname==="" || pwd.length===0 || pwd!==confirmpwd || email==="" || username==="") {
      setMessage(<Alert severity="warning">All fields are required...</Alert>)
    } else {
      registerUser(username, email, pwd, firstname, lastname).then(
        (result) => {
          if (result!=="SUCCESS") {
            // Error handling
            const errorsMsg=JSON.parse(result);
            for (let errorMsg in errorsMsg) {
              let msg=""
              for (let i=0; i < errorsMsg[errorMsg].length; i++) {
                msg=errorsMsg[errorMsg][i] + "\r\n"
              }
              if (errorMsg==="email") { setEmailE({error:true, helper:msg})}
              if (errorMsg==="password") { setPwdE({error:true, helper:msg})}
              if (errorMsg==="username") { setUsernameE({error:true, helper:msg})}
              if (errorMsg==="firstname") { setFirstnameE({error:true, helper:msg})}
              if (errorMsg==="lastname") { setLastnameE({error:true, helper:msg})}
            }
          } else {
            // Redirect to thanks page
            thanks();
          }
        }
      );
  
      
      
    }
  }


  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        {message}
        <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            error={emailE.error}
            helperText={emailE.helper}
            margin="dense"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={(newValue) => {
              setEmailE({error:false, helper:""})
              setEmail(newValue.target.value);
            }}
            autoComplete="email"
            autoFocus
            variant="outlined"
          />
          <TextField
            error={pwdE.error}
            helperText={pwdE.helper}
            margin="dense"
            required
            fullWidth
            name="password"
            onChange={(newValue) => {
              setPwdE({error:false, helper:""})
              setPwd(newValue.target.value);
              if (newValue.target.value!==confirmpwd) {
                setConfirmpwdE({error:true, helper:"Password must match..."})
              }
              else {
                setConfirmpwdE({error:false, helper:""})
              }
            }}
            label="Password"
            type="password"
            id="password"
            variant="outlined"
          />
          <TextField      
            error={confirmpwdE.error}
            helperText={confirmpwdE.helper}
            margin="dense"
            required
            fullWidth
            name="confirm-password"
            onChange={(newValue) => {
              setConfirmpwd(newValue.target.value);
              if (newValue.target.value!==pwd) {
                setConfirmpwdE({error:true, helper:"Password must match..."})
              }
              else {
                setConfirmpwdE({error:false, helper:""})
              }
            }}
            label="Confirm Password"
            type="password"
            id="confirm-password"
            variant="outlined"
          />
          <TextField
            error={usernameE.error}
            helperText={usernameE.helper}    
            margin="dense"
            required
            fullWidth
            name="username"
            onChange={(newValue) => {
              setUsernameE({error:false, helper:""})
              setUsername(newValue.target.value);
            }}
            label="Username"
            id="username"
            variant="outlined"
          />
          <TextField
            error={firstnameE.error}
            helperText={firstnameE.helper}    
            margin="dense"
            required
            fullWidth
            name="firstname"
            onChange={(newValue) => {
              setFirstnameE({error:false, helper:""})
              setFirstname(newValue.target.value);
            }}
            label="Firstname"
            id="firstname"
            variant="outlined"
          />
          <TextField
            error={lastnameE.error}
            helperText={lastnameE.helper}    
            margin="dense"
            required
            fullWidth
            name="lastname"
            onChange={(newValue) => {
              setLastnameE({error:false, helper:""})
              setLastname(newValue.target.value);
            }}
            label="Lastname"
            id="lastname"
            variant="outlined"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="primary"
          >
            Register
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
);
}


export default Register;
