const config = {
  api: {
    endpoint: 'https://apidev.meeteurs.com/api',
    wss: 'wss://apidev.meeteurs.com'
  },
  media: {
    endpoint: 'https://apidev.meeteurs.com'
  },
  web: {
    endpoint: 'https://dev.meeteurs.com'
  }
 };
 
 module.exports = config;