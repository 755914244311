import React from 'react'
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography, Container, Divider } from '@mui/material';

function Thanks() {
  const { t } = useTranslation();
  const { state } = useLocation();
  let path=state?.prevRoute?.pathname
  let search=state?.prevRoute?.search
  let url=path
  //let url="https://www.meeteurs.com"
  if (path!==undefined && search!==undefined) {
    url=path+search 
  }

  let link=t('thanks.link')
  const redirect=() => {
    if (url!==undefined) {
      return(
        <><br/>{t('thanks.redirect')} <Link to={url}>{link}</Link> {t('thanks.redirect_next')} </>
      )
    }
  }

  return (
    <Box
    sx={{
      margin:2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      minHeight: 'calc(100vh + 150px)'
    }}
    >
      <Container maxWidth="sm" sx={{ marginTop:10 }} >
        <Typography variant="h6" sx={{ mb:5 }}>
          {t('thanks.title')}
        </Typography>
        {t('thanks.text_1')}<br/>
        {t('thanks.text_2')}<br/><br/>
        <Divider/>
        {redirect()}
      </Container>
    </Box> 
  );
}

export default Thanks
