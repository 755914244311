import React, { useState, useContext } from 'react';
import { Box, Typography, Divider, Container, TextField, Button } from '@mui/material'
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/reducers/userSlice.js'
import { snackbarContext } from '../contexts/SnackbarContext';
import AppService from '../services/app.service';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { yellow } from '@mui/material/colors';
import isEmail from 'validator/lib/isEmail';

function Realm() {
  const { setSnack } = useContext(snackbarContext);
  const reduxuser = useSelector(selectUser);
  let navigate = useNavigate();
  const [realm, setRealm] = useState();
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false)

  let { realmId } = useParams();

  React.useEffect(() => {
    if (reduxuser?.type!=="P") {
      navigate("/");
    }
  }, [reduxuser])

  if (load===false) {
    AppService.getRealm(realmId, (results) => {
      if (results.status!==200) {
        setLoad(!load)
        setSnack({message:"Service unavailable, please try again in a few minute", severity:"error", open:true})
      } else {
        setRealm(results.data)
        setLoad(!load)
      }
    })
  }

  const changeAdmin=(user) => {
    if (user.user.email!==reduxuser.email) { // Not changing local user 
      let state=!user.isadmin
      let patchdata={
        "type":"admin",
        "email":user.user.email,
        "action":state
      }
      AppService.patchRealm(realmId, patchdata, (results) => {
        if (results.status!==204) {
          setSnack({message:"Service unavailable, please try again in a few minute", severity:"error", open:true})
        } else {
          setLoad(!load)
        }
      })
    }
  }

  const inviteUser=() => {
    let patchdata={
      "type":"invite",
      "email":email
    }
    AppService.patchRealm(realmId, patchdata, (results) => {
      if (results.status!==204 && results.status!==404) {
        setSnack({message:"User hasn't subscribed yet to Meeteurs", severity:"warning", open:true})
      } else if (results.status===404) {
        setSnack({message:"Service unavailable, please try again in a few minute", severity:"error", open:true})
      } else {
        setEmail("")
        setLoad(!load)
      }
    })
  }

  const deleteUser=(user)=> {
    if (user.user.email!==reduxuser.email) { // Not deleting local user 
      let patchdata={
        "type":"remove",
        "email":user.user.email
      }
      AppService.patchRealm(realmId, patchdata, (results) => {
        if (results.status!==204) {
          setSnack({message:"Service unavailable, please try again in a few minute", severity:"error", open:true})
        } else {
          setLoad(!load)
        }
      })
    }
  }
  
  const displayAdmin=(user) => {
    if (user.isadmin) {
      return(<AdminPanelSettingsIcon sx={{ color: yellow[500] }} onClick={() => changeAdmin(user)}/>)
    } else if (user.joined===false) {
      return(<HourglassEmptyIcon/>)
    } else {
      return(<AdminPanelSettingsIcon onClick={() => changeAdmin(user)}/>)
    }
  }

  

  if (realm===undefined) {
    return(<>Loading...</>)
  } else {
  
  return (
      
        <Box
          sx={{
            margin:2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            minHeight: 'calc(100vh + 150px)'
          }}
        >
          <Typography variant="h6">
            Manage Realm
          </Typography>
          <Container maxWidth="sm" sx={{ marginTop:10 }} >
            <Divider/>
            Realm: {realm.name}
            <Divider/>
            <Box
              sx={{
                margin:2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'left',
              }}
              >
              <TextField
                  fullWidth
                  required
                  value={email}
                  onChange={(newValue) => {
                    setEmail(newValue.target.value);
                  }}
                  id="email"
                  label="Invite people (email)"
                  name="email"
                  variant="outlined"
                />
              <Button
                variant="contained"
                onClick={()=> {inviteUser()}}
                sx={{ml:1}}
              >
                Go
              </Button>
            </Box>
            <Divider/>
            Users:
            {realm.realmusers.map((user,idx) => (
              <Box
              sx={{
                margin:2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'left',
              }}
              key={idx}
              >
                <Typography width='100%'>{user.user.email}</Typography>
                {displayAdmin(user)}
                <DeleteIcon onClick={() => {deleteUser(user)}} />
              </Box>
            ))}
          </Container>
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
          >
            <Button
                variant="contained"
                onClick={()=> {navigate(-1)}}
                sx={{ml:1}}
            >
              OK
            </Button>
          </Box>
        </Box>
    );
  }
}

export default Realm
