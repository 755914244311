import React, { createContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const dialogContext = createContext({});

const DialogProvider = ({ children }) => {
  const [dialog, setDialog] = useState({ title:'', message: [], severity: 'error', open: false, okButton:'Agree', cancelButton:'Cancel'});

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDialog({...dialog, open: false});
    dialog.cancelCallback();
  };

  const handleAgree = (event, reason) => {
    setDialog({...dialog, open: false});
    dialog.callback();
  };

  return (
    <dialogContext.Provider value={{ dialog, setDialog }}>
      { children }
      <Dialog
          key="DialogKey"
          open={dialog.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dialog.title}
          </DialogTitle>
          <DialogContent>
            {dialog.message.map(line => (<DialogContentText id="alert-dialog-description">{line}</DialogContentText>))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{dialog.cancelButton}</Button>
            <Button onClick={handleAgree} autoFocus>
              {dialog.okButton}
            </Button>
          </DialogActions>
        </Dialog>
    </dialogContext.Provider>
  );
};

export default DialogProvider;
